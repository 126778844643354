export const FLOOR_5_DATA = [
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 851,
    sceneName: 'scene_Lounatuuli_5krs_as851_B',
    sceneName_future: 'scene_Lounatuuli_5krs_as851_B_future',
    name: '851',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 821,
      3: 831,
      4: 841,
      6: 861,
      7: 871,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 852,
    sceneName: 'scene_Lounatuuli_5krs_as852_B',
    sceneName_future: 'scene_Lounatuuli_5krs_as852_B_future',
    name: '852',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      3: 832,
      4: 842,
      6: 862,
      7: 872,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 853,
    sceneName: 'scene_Lounatuuli_5krs_as853_B',
    sceneName_future: 'scene_Lounatuuli_5krs_as853_B_future',
    name: '853',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      3: 833,
      4: 843,
      6: 863,
      7: 873,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 854,
    sceneName: 'scene_Lounatuuli_5krs_as854_B',
    sceneName_future: 'scene_Lounatuuli_5krs_as854_B_future',
    name: '854',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 822,
      3: 834,
      4: 844,
      6: 864,
      7: 874,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 855,
    sceneName: 'scene_Lounatuuli_5krs_as855_B',
    sceneName_future: 'scene_Lounatuuli_5krs_as855_B_future',
    name: '855',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 823,
      3: 835,
      4: 845,
      6: 865,
      7: 875,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 856,
    sceneName: 'scene_Lounatuuli_5krs_as856_B',
    sceneName_future: 'scene_Lounatuuli_5krs_as856_B_future',
    name: '856',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 824,
      3: 836,
      4: 846,
      6: 866,
      7: 876,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 857,
    sceneName: 'scene_Lounatuuli_5krs_as857_B',
    sceneName_future: 'scene_Lounatuuli_5krs_as857_B_future',
    name: '857',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 824,
      3: 837,
      4: 847,
      6: 867,
      7: 877,
    },
  },
]
