import * as React from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'

import ArrowIcon from 'icons/arrow'
import { FLOORS_DATA } from 'data/floors_data'
import { initialApartments } from 'data/initialApartments'
import Collapsible from './Collapsible'
import Floor7 from 'floor-planes/floor7'
import Floor6 from 'floor-planes/floor6'
import Floor5 from 'floor-planes/floor5'
import Floor4 from 'floor-planes/floor4'
import Floor3 from 'floor-planes/floor3'
import Floor2 from 'floor-planes/floor2'
import Floor1 from 'floor-planes/floor1'
import { formatApartmentName } from 'utils/helper'

const floorComponents = {
  1: Floor1,
  2: Floor2,
  3: Floor3,
  4: Floor4,
  5: Floor5,
  6: Floor6,
  7: Floor7,
}

const getHighestFloor = floorsData => {
  const unlockFloors = floorsData.length
    ? floorsData
        .filter(({ isOpen }) => isOpen === true)
        .map(({ number }) => number)
        .sort()
    : []
  const sortedUnlockFloors = _.sortBy(unlockFloors)
  const highestFloor = sortedUnlockFloors[sortedUnlockFloors.length - 1]
  return highestFloor
}

const getLowestFloor = floorsData => {
  const unlockFloors = floorsData.length
    ? floorsData
        .filter(({ isOpen }) => isOpen === true)
        .map(({ number }) => number)
        .sort()
    : []
  const sortedUnlockFloors = _.sortBy(unlockFloors)
  const lowestFloor = sortedUnlockFloors[0]
  return lowestFloor
}

function getAvailableApartments(apartments, floorNumber) {
  const apartmentsOnThisFloor = apartments.filter(
    apartment => apartment.floor === floorNumber,
  )
  const availableApartments = apartmentsOnThisFloor.filter(
    apartment => apartment.status !== 'sold',
  )

  return availableApartments
}

export default function NavigationPanel({
  krpano,
  hLookAt,
  apartments,
  floorsData,
}) {
  const highestFloor = getHighestFloor(floorsData)
  const lowestFloor = getLowestFloor(floorsData)
  const unlockFloors = floorsData.length
    ? floorsData
        .filter(({ isOpen }) => isOpen === true)
        .map(({ number }) => number)
        .sort()
    : []
  const sortedUnlockFloors = _.sortBy(unlockFloors)
  const [activeFloor, setActiveFloor] = React.useState(highestFloor)
  const [activeApartment, setActiveApartment] = React.useState(
    initialApartments[highestFloor],
  )
  const [collapse, setCollapse] = React.useState(false)
  const [index, setIndex] = React.useState(undefined)
  const [isSceneNameFuture, setIsSceneNameFuture] = React.useState(false)
  const [currentFace, setCurrentFace] = React.useState(
    initialApartments[highestFloor].face,
  )
  const floors = Object.keys(FLOORS_DATA)

  React.useEffect(() => {
    if (
      window.matchMedia('(orientation: landscape)').matches &&
      window.matchMedia('(max-device-width: 667px)').matches
    ) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }, [])

  const loadScene = React.useCallback(
    (sceneName, lookAt, activeFace) => {
      if (krpano) {
        const hlookat = krpano.get('view.hlookat')
        const vlookat = krpano.get('view.vlookat')
        const fov = krpano.get('view.fov')
        const distortion = krpano.get('view.distortion')

        krpano.call(
          `loadscene(${sceneName}, null, MERGE, BLEND(0.5, easeInCubic)); lookat(${lookAt});`,
        )

        if (currentFace === activeFace) {
          krpano.set('view.hlookat', hlookat)
          krpano.set('view.vlookat', vlookat)
          krpano.set('view.fov', fov)
          krpano.set('view.distortion', distortion)
        }
      }
    },
    [currentFace, krpano],
  )

  const changeView = React.useCallback(
    (floorNumber, aptNumber) => {
      const apartment = FLOORS_DATA[floorNumber].find(
        e => e.apartmentNumber === aptNumber,
      )
      const lookAt = apartment.lookAt
      const activeFace = apartment.face
      const sceneName = isSceneNameFuture
        ? apartment.sceneName_future
        : apartment.sceneName

      setActiveFloor(floorNumber)
      setActiveApartment(apartment)
      setCurrentFace(activeFace)
      loadScene(sceneName, lookAt, activeFace)
    },
    [isSceneNameFuture, loadScene],
  )

  const changeViewToAvailableApartmentOnly = React.useCallback(
    (floorNumber, aptNumber) => {
      const apartmentsOnThisFloor = apartments.filter(
        apartment => apartment.floor === floorNumber,
      )
      const availableApartments = apartmentsOnThisFloor.filter(
        apartment => apartment.status !== 'sold',
      )
      const chosenApartmentStatus = apartmentsOnThisFloor.find(
        apartment => apartment.number === aptNumber,
      )?.status
      const isSoldApartment = chosenApartmentStatus === 'sold' || false

      if (isSoldApartment) {
        if (!availableApartments.length && floorNumber === highestFloor) {
          const availableApartmentsOnPreviousFloor = getAvailableApartments(
            apartments,
            highestFloor - 1,
          )
          const sortedApartmentsByNumber = _.sortBy(
            availableApartmentsOnPreviousFloor,
            ['number'],
          )
          const goToApartment =
            sortedApartmentsByNumber[sortedApartmentsByNumber.length - 1].number

          changeView(highestFloor - 1, goToApartment)
        } else {
          const goToApartment = availableApartments[0].number
          changeView(floorNumber, goToApartment)
        }
      } else {
        changeView(floorNumber, aptNumber)
      }
    },
    [apartments, changeView, highestFloor],
  )

  const changeFloor = navCase => {
    const { apartmentNumber, relatedApartments, floor } = activeApartment
    const targetedFloor = changeActiveFloor(navCase) || floor
    const targetedApartmentNumber =
      relatedApartments[targetedFloor] || apartmentNumber
    // getRelatedApartment(relatedApartments, targetedFloor)
    changeViewToAvailableApartmentOnly(targetedFloor, targetedApartmentNumber)
  }

  const changeActiveFloor = navCase => {
    let targetedFloor

    if (
      navCase === 'up' &&
      sortedUnlockFloors.indexOf(activeFloor) < sortedUnlockFloors.length - 1
    ) {
      const nextIndex = sortedUnlockFloors.indexOf(activeFloor) + 1
      targetedFloor = sortedUnlockFloors[nextIndex]
      const availableApartments = getAvailableApartments(
        apartments,
        targetedFloor,
      )

      if (!availableApartments.length && targetedFloor === highestFloor) {
        setActiveFloor(activeFloor)
      } else if (!availableApartments.length) {
        setActiveFloor(targetedFloor + 1)
        targetedFloor = targetedFloor + 1
      } else {
        setActiveFloor(targetedFloor)
      }
    }
    if (navCase === 'down' && sortedUnlockFloors.indexOf(activeFloor) > 0) {
      const nextIndex = sortedUnlockFloors.indexOf(activeFloor) - 1
      targetedFloor = sortedUnlockFloors[nextIndex]
      const availableApartments = getAvailableApartments(
        apartments,
        targetedFloor,
      )

      if (!availableApartments.length && targetedFloor === lowestFloor) {
        setActiveFloor(activeFloor)
      } else if (!availableApartments.length) {
        setActiveFloor(targetedFloor - 1)
        targetedFloor = targetedFloor - 1
      } else {
        setActiveFloor(targetedFloor)
      }
    }
    return targetedFloor
  }

  const changeApartInCollapsible = navCase => {
    const apartments = Object.values(FLOORS_DATA[activeFloor]).map(
      e => e.apartmentNumber,
    )
    const { apartmentNumber } = activeApartment
    const apartmentIndex = apartments.indexOf(apartmentNumber)

    if (navCase === 'next' && apartmentIndex < apartments.length - 1) {
      setIndex(apartmentIndex + 1)
      changeViewToAvailableApartmentOnly(
        activeFloor,
        apartments[apartmentIndex + 1],
      )
    }
    if (navCase === 'previous' && apartmentIndex > 0) {
      changeViewToAvailableApartmentOnly(
        activeFloor,
        apartments[apartmentIndex - 1],
      )
      setIndex(apartmentIndex - 1)
    }
  }

  React.useEffect(() => {
    if (krpano) {
      changeViewToAvailableApartmentOnly(
        activeFloor,
        activeApartment.apartmentNumber,
      )
    }
  }, [
    activeApartment.apartmentNumber,
    activeFloor,
    changeViewToAvailableApartmentOnly,
    krpano,
  ])

  const toggleSceneNameFuture = () => setIsSceneNameFuture(!isSceneNameFuture)

  const floorPlanProps = {
    activeApartment,
    changeFloor,
    changeView,
    hLookAt,
    toggleSceneNameFuture,
    isSceneNameFuture,
    apartments,
  }

  const renderUnlockFloorComponents = () => {
    return !!sortedUnlockFloors
      ? sortedUnlockFloors.map(floorNumber => {
          const UnlockFloor = floorComponents[floorNumber]

          return (
            <UnlockFloor
              key={floorNumber}
              floor={floorNumber}
              {...floorPlanProps}
            />
          )
        })
      : null
  }

  return (
    <Wrapper>
      <Collapsible collapse={collapse} setCollapse={setCollapse}>
        {collapse ? (
          <ApartmentInfo>
            <ElevatorIcons>
              <div style={{ marginRight: '10px' }}>
                <ArrowIcon
                  onClick={() => changeFloor('up')}
                  disabled={activeFloor === Number(floors[floors.length - 1])}
                />
              </div>
              <div>
                <ArrowIcon
                  rotateDeg="180deg"
                  onClick={() => changeFloor('down')}
                  disabled={activeFloor === Number(floors[0])}
                />
              </div>
            </ElevatorIcons>
            <p className="floor">Kerros {activeApartment.floor}</p>
            <p className="apartment">
              Huoneisto {formatApartmentName(activeApartment.name)}
            </p>
            <ApartmentIcons>
              <div style={{ marginRight: '10px' }}>
                <ArrowIcon
                  rotateDeg="-90deg"
                  onClick={() => changeApartInCollapsible('next')}
                  disabled={index === 3}
                />
              </div>
              <div>
                <ArrowIcon
                  rotateDeg="90deg"
                  onClick={() => changeApartInCollapsible('previous')}
                  disabled={index === 0}
                />
              </div>
            </ApartmentIcons>
          </ApartmentInfo>
        ) : (
          <div style={{ position: 'relative' }}>
            <SwitchComponents active={activeFloor}>
              {renderUnlockFloorComponents()}
            </SwitchComponents>

            <Link
              href="https://tietoa.fi/palvelut/sujuvat-sovellukset/tietoa-360-maisemakone/"
              target="_blank"
            >
              Tietoa 360 Maisemakone
            </Link>

            {/* <UploadData
              collectionName="finnoo-lounatuuli-B"
              floors={initialFloorsData}
              apartments={initialApartmentsData}
            /> */}
          </div>
        )}
      </Collapsible>
    </Wrapper>
  )
}

function SwitchComponents({ active, children }) {
  return !!children
    ? children.filter(child => child.props.floor === active)
    : null
}

// function UploadData({ floors, apartments, collectionName }) {
//   const { documents } = useCollection(collectionName)
//   const { addDocument } = useFirestore('projects')

//   return (
//     <button
//       onClick={() =>
//         addDocument(collectionName, {
//           id: collectionName,
//           name: 'Finnoo - Lounatuuli 8',
//           floors,
//           apartments,
//           company: 'bonava',
//         })
//       }
//     >
//       Upload data to Firestore
//     </button>
//   )
// }

const Link = styled.a`
  position: absolute;
  right: -65px;
  top: 90px;
  transform: rotate(270deg);
  font-size: 13px;
  opacity: 0.5;
  color: #3f3f3c;
  user-select: none;
  text-decoration: none;

  @media (max-width: 575px) {
    display: none;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    display: none;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    display: none;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 510px;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  background-color: #86cfc2;
  z-index: 2;

  @media (max-width: 575px) {
    right: 0;
    width: 100%;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 300px;
    right: 0;
    bottom: 0;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 300px;
    right: 0;
    bottom: 0;
  }
`

const ApartmentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    min-width: 50px;
    margin: 0;
    padding: 10px 20px;
    color: var(--light);
    font-size: 14px;
  }

  .floor {
    background-color: var(--green-color);
    color: var(--light);
  }

  .apartment {
    min-width: 120px;
    margin-right: 0;
    background-color: var(--primary-color);
    color: var(--light);
  }

  @media (max-width: 575px) {
    p {
      padding: 5px 10px;
      font-size: 10px;
    }

    .apartment {
      min-width: 80px;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    p {
      padding: 5px;
      font-size: 10px;
    }

    .floor {
      min-width: 60px;
    }

    .apartment {
      min-width: 90px;
      margin-right: 0;
      background-color: var(--secondary-color-80);
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    p {
      padding: 5px;
      font-size: 10px;
    }

    .floor {
      min-width: 60px;
    }

    .apartment {
      min-width: 90px;
      margin-right: 0;
      background-color: var(--secondary-color-80);
    }
  }
`

const ElevatorIcons = styled.div`
  display: flex;
  margin-right: 2rem;
  padding-top: 5px;

  @media (max-width: 575px) {
    margin-right: 1rem;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-right: 14px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-right: 14px;
  }
`
const ApartmentIcons = styled.div`
  display: flex;
  margin-left: 2rem;
  padding-top: 5px;

  @media (max-width: 575px) {
    margin-left: 1rem;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-left: 14px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-left: 14px;
  }
`

// const initialFloorsData = [
//   { id: 'floor-1', number: 1, isOpen: true },
//   { id: 'floor-2', number: 2, isOpen: true },
//   { id: 'floor-3', number: 3, isOpen: true },
//   { id: 'floor-4', number: 4, isOpen: true },
//   { id: 'floor-5', number: 5, isOpen: true },
//   { id: 'floor-6', number: 6, isOpen: true },
//   { id: 'floor-7', number: 7, isOpen: true },
// ]

// const initialApartmentsData = [
//   {
//     id: '811',
//     status: 'available',
//     floor: 1,
//     building: 'B',
//     number: 811,
//     sceneName: 'scene_Lounatuuli_1krs_as811_B',
//     sceneName_future: 'scene_Lounatuuli_1krs_as811_B_future',
//     name: '811',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 823,
//       3: 835,
//       4: 845,
//       5: 855,
//       6: 865,
//       7: 875,
//     },
//   },
//   {
//     id: '812',
//     status: 'available',
//     floor: 1,
//     building: 'B',
//     number: 812,
//     sceneName: 'scene_Lounatuuli_1krs_as812_B',
//     sceneName_future: 'scene_Lounatuuli_1krs_as812_B_future',
//     name: '812',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 824,
//       3: 836,
//       4: 846,
//       5: 856,
//       6: 866,
//       7: 876,
//     },
//   },
//   {
//     id: '821',
//     status: 'available',
//     floor: 2,
//     building: 'B',
//     number: 821,
//     sceneName: 'scene_Lounatuuli_2krs_as821_B',
//     sceneName_future: 'scene_Lounatuuli_2krs_as821_B_future',
//     name: '821',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       1: 811,
//       3: 833,
//       4: 843,
//       5: 853,
//       6: 863,
//       7: 873,
//     },
//   },
//   {
//     id: '822',
//     status: 'available',
//     floor: 2,
//     building: 'B',
//     number: 822,
//     sceneName: 'scene_Lounatuuli_2krs_as822_B',
//     sceneName_future: 'scene_Lounatuuli_2krs_as822_B_future',
//     name: '822',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       1: 811,
//       3: 834,
//       4: 844,
//       5: 854,
//       6: 864,
//       7: 874,
//     },
//   },
//   {
//     id: '823',
//     status: 'available',
//     floor: 2,
//     building: 'B',
//     number: 823,
//     sceneName: 'scene_Lounatuuli_2krs_as823_B',
//     sceneName_future: 'scene_Lounatuuli_2krs_as823_B_future',
//     name: '823',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       1: 811,
//       3: 835,
//       4: 845,
//       5: 855,
//       6: 865,
//       7: 875,
//     },
//   },
//   {
//     id: '824',
//     status: 'available',
//     floor: 2,
//     building: 'B',
//     number: 824,
//     sceneName: 'scene_Lounatuuli_2krs_as824_B',
//     sceneName_future: 'scene_Lounatuuli_2krs_as824_B_future',
//     name: '824',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       1: 812,
//       3: 836,
//       4: 846,
//       5: 856,
//       6: 866,
//       7: 876,
//     },
//   },
//   {
//     id: '825',
//     status: 'available',
//     floor: 2,
//     building: 'B',
//     number: 825,
//     sceneName: 'scene_Lounatuuli_2krs_as825_B',
//     sceneName_future: 'scene_Lounatuuli_2krs_as825_B_future',
//     name: '825',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       1: 812,
//       3: 837,
//       4: 847,
//       5: 857,
//       6: 867,
//       7: 877,
//     },
//   },
//   {
//     id: '831',
//     status: 'available',
//     floor: 3,
//     building: 'B',
//     number: 831,
//     sceneName: 'scene_Lounatuuli_3krs_as831_B',
//     sceneName_future: 'scene_Lounatuuli_3krs_as831_B_future',
//     name: '831',
//     lookAt: '-85.7, 7, 120',
//     face: 'west',

//     relatedApartments: {
//       2: 821,
//       4: 841,
//       5: 851,
//       6: 861,
//       7: 871,
//     },
//   },
//   {
//     id: '832',
//     status: 'available',
//     floor: 3,
//     building: 'B',
//     number: 832,
//     sceneName: 'scene_Lounatuuli_3krs_as832_B',
//     sceneName_future: 'scene_Lounatuuli_3krs_as832_B_future',
//     name: '832',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 821,
//       4: 842,
//       5: 852,
//       6: 862,
//       7: 872,
//     },
//   },
//   {
//     id: '833',
//     status: 'available',
//     floor: 3,
//     building: 'B',
//     number: 833,
//     sceneName: 'scene_Lounatuuli_3krs_as833_B',
//     sceneName_future: 'scene_Lounatuuli_3krs_as833_B_future',
//     name: '833',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 821,
//       4: 843,
//       5: 853,
//       6: 863,
//       7: 873,
//     },
//   },
//   {
//     id: '834',
//     status: 'available',
//     floor: 3,
//     building: 'B',
//     number: 834,
//     sceneName: 'scene_Lounatuuli_3krs_as834_B',
//     sceneName_future: 'scene_Lounatuuli_3krs_as834_B_future',
//     name: '834',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 822,
//       4: 844,
//       5: 854,
//       6: 864,
//       7: 874,
//     },
//   },
//   {
//     id: '835',
//     status: 'available',
//     floor: 3,
//     building: 'B',
//     number: 835,
//     sceneName: 'scene_Lounatuuli_3krs_as835_B',
//     sceneName_future: 'scene_Lounatuuli_3krs_as835_B_future',
//     name: '835',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 823,
//       4: 845,
//       5: 855,
//       6: 865,
//       7: 875,
//     },
//   },
//   {
//     id: '836',
//     status: 'available',
//     floor: 3,
//     building: 'B',
//     number: 836,
//     sceneName: 'scene_Lounatuuli_3krs_as836_B',
//     sceneName_future: 'scene_Lounatuuli_3krs_as836_B_future',
//     name: '836',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 824,
//       4: 846,
//       5: 856,
//       6: 866,
//       7: 876,
//     },
//   },
//   {
//     id: '837',
//     status: 'available',
//     floor: 3,
//     building: 'B',
//     number: 837,
//     sceneName: 'scene_Lounatuuli_3krs_as837_B',
//     sceneName_future: 'scene_Lounatuuli_3krs_as837_B_future',
//     name: '837',
//     lookAt: '-85.7, 7, 120',
//     face: 'west',

//     relatedApartments: {
//       2: 824,
//       4: 847,
//       5: 857,
//       6: 867,
//       7: 877,
//     },
//   },
//   {
//     id: '841',
//     status: 'available',
//     floor: 4,
//     building: 'B',
//     number: 841,
//     sceneName: 'scene_Lounatuuli_4krs_as841_B',
//     sceneName_future: 'scene_Lounatuuli_4krs_as841_B_future',
//     name: '841',
//     lookAt: '-85.7, 7, 120',
//     face: 'west',

//     relatedApartments: {
//       2: 821,
//       3: 831,
//       5: 851,
//       6: 861,
//       7: 871,
//     },
//   },
//   {
//     id: '842',
//     status: 'available',
//     floor: 4,
//     building: 'B',
//     number: 842,
//     sceneName: 'scene_Lounatuuli_4krs_as842_B',
//     sceneName_future: 'scene_Lounatuuli_4krs_as842_B_future',
//     name: '842',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 821,
//       3: 832,
//       5: 852,
//       6: 862,
//       7: 872,
//     },
//   },
//   {
//     id: '843',
//     status: 'available',
//     floor: 4,
//     building: 'B',
//     number: 843,
//     sceneName: 'scene_Lounatuuli_4krs_as843_B',
//     sceneName_future: 'scene_Lounatuuli_4krs_as843_B_future',
//     name: '843',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 821,
//       3: 833,
//       5: 853,
//       6: 863,
//       7: 873,
//     },
//   },
//   {
//     id: '844',
//     status: 'available',
//     floor: 4,
//     building: 'B',
//     number: 844,
//     sceneName: 'scene_Lounatuuli_4krs_as844_B',
//     sceneName_future: 'scene_Lounatuuli_4krs_as844_B_future',
//     name: '844',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 822,
//       3: 834,
//       5: 854,
//       6: 864,
//       7: 874,
//     },
//   },
//   {
//     id: '845',
//     status: 'available',
//     floor: 4,
//     building: 'B',
//     number: 845,
//     sceneName: 'scene_Lounatuuli_4krs_as845_B',
//     sceneName_future: 'scene_Lounatuuli_4krs_as845_B_future',
//     name: '845',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 823,
//       3: 835,
//       5: 855,
//       6: 865,
//       7: 875,
//     },
//   },
//   {
//     id: '846',
//     status: 'available',
//     floor: 4,
//     building: 'B',
//     number: 846,
//     sceneName: 'scene_Lounatuuli_4krs_as846_B',
//     sceneName_future: 'scene_Lounatuuli_4krs_as846_B_future',
//     name: '846',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 824,
//       3: 836,
//       5: 856,
//       6: 866,
//       7: 876,
//     },
//   },
//   {
//     id: '847',
//     status: 'available',
//     floor: 4,
//     building: 'B',
//     number: 847,
//     sceneName: 'scene_Lounatuuli_4krs_as847_B',
//     sceneName_future: 'scene_Lounatuuli_4krs_as847_B_future',
//     name: '847',
//     lookAt: '-85.7, 7, 120',
//     face: 'west',

//     relatedApartments: {
//       2: 824,
//       3: 837,
//       5: 857,
//       6: 867,
//       7: 877,
//     },
//   },
//   {
//     id: '851',
//     status: 'available',
//     floor: 5,
//     building: 'B',
//     number: 851,
//     sceneName: 'scene_Lounatuuli_5krs_as851_B',
//     sceneName_future: 'scene_Lounatuuli_5krs_as851_B_future',
//     name: '851',
//     lookAt: '-85.7, 7, 120',
//     face: 'west',

//     relatedApartments: {
//       2: 821,
//       3: 831,
//       4: 841,
//       6: 861,
//       7: 871,
//     },
//   },
//   {
//     id: '852',
//     status: 'available',
//     floor: 5,
//     building: 'B',
//     number: 852,
//     sceneName: 'scene_Lounatuuli_5krs_as852_B',
//     sceneName_future: 'scene_Lounatuuli_5krs_as852_B_future',
//     name: '852',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 821,
//       3: 832,
//       4: 842,
//       6: 862,
//       7: 872,
//     },
//   },
//   {
//     id: '853',
//     status: 'available',
//     floor: 5,
//     building: 'B',
//     number: 853,
//     sceneName: 'scene_Lounatuuli_5krs_as853_B',
//     sceneName_future: 'scene_Lounatuuli_5krs_as853_B_future',
//     name: '853',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 821,
//       3: 833,
//       4: 843,
//       6: 863,
//       7: 873,
//     },
//   },
//   {
//     id: '854',
//     status: 'available',
//     floor: 5,
//     building: 'B',
//     number: 854,
//     sceneName: 'scene_Lounatuuli_5krs_as854_B',
//     sceneName_future: 'scene_Lounatuuli_5krs_as854_B_future',
//     name: '854',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 822,
//       3: 834,
//       4: 844,
//       6: 864,
//       7: 874,
//     },
//   },
//   {
//     id: '855',
//     status: 'available',
//     floor: 5,
//     building: 'B',
//     number: 855,
//     sceneName: 'scene_Lounatuuli_5krs_as855_B',
//     sceneName_future: 'scene_Lounatuuli_5krs_as855_B_future',
//     name: '855',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 823,
//       3: 835,
//       4: 845,
//       6: 865,
//       7: 875,
//     },
//   },
//   {
//     id: '856',
//     status: 'available',
//     floor: 5,
//     building: 'B',
//     number: 856,
//     sceneName: 'scene_Lounatuuli_5krs_as856_B',
//     sceneName_future: 'scene_Lounatuuli_5krs_as856_B_future',
//     name: '856',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 824,
//       3: 836,
//       4: 846,
//       6: 866,
//       7: 876,
//     },
//   },
//   {
//     id: '857',
//     status: 'available',
//     floor: 5,
//     building: 'B',
//     number: 857,
//     sceneName: 'scene_Lounatuuli_5krs_as857_B',
//     sceneName_future: 'scene_Lounatuuli_5krs_as857_B_future',
//     name: '857',
//     lookAt: '-85.7, 7, 120',
//     face: 'west',

//     relatedApartments: {
//       2: 824,
//       3: 837,
//       4: 847,
//       6: 867,
//       7: 877,
//     },
//   },
//   {
//     id: '861',
//     status: 'available',
//     floor: 6,
//     building: 'B',
//     number: 861,
//     sceneName: 'scene_Lounatuuli_6krs_as861_B',
//     sceneName_future: 'scene_Lounatuuli_6krs_as861_B_future',
//     name: '861',
//     lookAt: '-85.7, 7, 120',
//     face: 'west',

//     relatedApartments: {
//       2: 821,
//       3: 831,
//       4: 841,
//       5: 851,
//       7: 871,
//     },
//   },
//   {
//     id: '862',
//     status: 'available',
//     floor: 6,
//     building: 'B',
//     number: 862,
//     sceneName: 'scene_Lounatuuli_6krs_as862_B',
//     sceneName_future: 'scene_Lounatuuli_6krs_as862_B_future',
//     name: '862',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 821,
//       3: 832,
//       4: 842,
//       5: 852,
//       7: 872,
//     },
//   },
//   {
//     id: '863',
//     status: 'available',
//     floor: 6,
//     building: 'B',
//     number: 863,
//     sceneName: 'scene_Lounatuuli_6krs_as863_B',
//     sceneName_future: 'scene_Lounatuuli_6krs_as863_B_future',
//     name: '863',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 821,
//       3: 833,
//       4: 843,
//       5: 853,
//       7: 873,
//     },
//   },
//   {
//     id: '864',
//     status: 'available',
//     floor: 6,
//     building: 'B',
//     number: 864,
//     sceneName: 'scene_Lounatuuli_6krs_as864_B',
//     sceneName_future: 'scene_Lounatuuli_6krs_as864_B_future',
//     name: '864',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 822,
//       3: 834,
//       4: 844,
//       5: 854,
//       7: 874,
//     },
//   },
//   {
//     id: '865',
//     status: 'available',
//     floor: 6,
//     building: 'B',
//     number: 865,
//     sceneName: 'scene_Lounatuuli_6krs_as865_B',
//     sceneName_future: 'scene_Lounatuuli_6krs_as865_B_future',
//     name: '865',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 823,
//       3: 835,
//       4: 845,
//       5: 855,
//       7: 875,
//     },
//   },
//   {
//     id: '866',
//     status: 'available',
//     floor: 6,
//     building: 'B',
//     number: 866,
//     sceneName: 'scene_Lounatuuli_6krs_as866_B',
//     sceneName_future: 'scene_Lounatuuli_6krs_as866_B_future',
//     name: '866',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 824,
//       3: 836,
//       4: 846,
//       5: 856,
//       7: 876,
//     },
//   },
//   {
//     id: '867',
//     status: 'available',
//     floor: 6,
//     building: 'B',
//     number: 867,
//     sceneName: 'scene_Lounatuuli_6krs_as867_B',
//     sceneName_future: 'scene_Lounatuuli_6krs_as867_B_future',
//     name: '867',
//     lookAt: '-85.7, 7, 120',
//     face: 'west',

//     relatedApartments: {
//       2: 824,
//       3: 837,
//       4: 847,
//       5: 857,
//       7: 877,
//     },
//   },
//   {
//     id: '871',
//     status: 'available',
//     floor: 7,
//     building: 'B',
//     number: 871,
//     sceneName: 'scene_Lounatuuli_7krs_as871_B',
//     sceneName_future: 'scene_Lounatuuli_7krs_as871_B_future',
//     name: '871',
//     lookAt: '-85.7, 7, 120',
//     face: 'west',

//     relatedApartments: {
//       2: 821,
//       3: 831,
//       4: 841,
//       5: 851,
//       6: 861,
//     },
//   },
//   {
//     id: '872',
//     status: 'available',
//     floor: 7,
//     building: 'B',
//     number: 872,
//     sceneName: 'scene_Lounatuuli_7krs_as872_B',
//     sceneName_future: 'scene_Lounatuuli_7krs_as872_B_future',
//     name: '872',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 821,
//       3: 832,
//       4: 842,
//       5: 852,
//       6: 862,
//     },
//   },
//   {
//     id: '873',
//     status: 'available',
//     floor: 7,
//     building: 'B',
//     number: 873,
//     sceneName: 'scene_Lounatuuli_7krs_as873_B',
//     sceneName_future: 'scene_Lounatuuli_7krs_as873_B_future',
//     name: '873',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 821,
//       3: 833,
//       4: 843,
//       5: 853,
//       6: 863,
//     },
//   },
//   {
//     id: '874',
//     status: 'available',
//     floor: 7,
//     building: 'B',
//     number: 874,
//     sceneName: 'scene_Lounatuuli_7krs_as874_B',
//     sceneName_future: 'scene_Lounatuuli_7krs_as874_B_future',
//     name: '874',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 822,
//       3: 834,
//       4: 844,
//       5: 854,
//       6: 864,
//     },
//   },
//   {
//     id: '875',
//     status: 'available',
//     floor: 7,
//     building: 'B',
//     number: 875,
//     sceneName: 'scene_Lounatuuli_7krs_as875_B',
//     sceneName_future: 'scene_Lounatuuli_7krs_as875_B_future',
//     name: '875',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 823,
//       3: 835,
//       4: 845,
//       5: 855,
//       6: 865,
//     },
//   },
//   {
//     id: '876',
//     status: 'available',
//     floor: 7,
//     building: 'B',
//     number: 876,
//     sceneName: 'scene_Lounatuuli_7krs_as876_B',
//     sceneName_future: 'scene_Lounatuuli_7krs_as876_B_future',
//     name: '876',
//     lookAt: '94, 7, 120',
//     face: 'east',

//     relatedApartments: {
//       2: 824,
//       3: 836,
//       4: 846,
//       5: 856,
//       6: 866,
//     },
//   },
//   {
//     id: '877',
//     status: 'available',
//     floor: 7,
//     building: 'B',
//     number: 877,
//     sceneName: 'scene_Lounatuuli_7krs_as877_B',
//     sceneName_future: 'scene_Lounatuuli_7krs_as877_B_future',
//     name: '877',
//     lookAt: '-85.7, 7, 120',
//     face: 'west',

//     relatedApartments: {
//       2: 824,
//       3: 837,
//       4: 847,
//       5: 857,
//       6: 867,
//     },
//   },
// ]
