import React from 'react'
import styled from 'styled-components'

export default function BonavaLogo({ width = '30px', height = '100%' }) {
  return (
    <SVG
      viewBox="0 0 491 218"
      id="logotype_primary"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
    >
      <path d="M60 196.2c0 13.2-11 20.9-25.4 20.9H0v-75.3h34.8c14.1 0 23.5 7.5 23.5 19.4.1 7.6-4.5 14.5-11.6 17.2 8.1 2.5 13.3 8.8 13.3 17.8zm-47.6-43.1v20.8h20.7c7.4 0 12.8-3.9 12.8-10.6s-5.2-10.2-12.2-10.2H12.4zm35.2 42c0-6.8-5.3-10.5-13.1-10.5l-22.1.1v21.1h22.2c7.3 0 13-3.7 13-10.7zm34.6-15.6c0-21.5 13.5-38.9 36.1-38.9s36 17.1 36 38.7-13.5 38.9-36 38.9-36.1-17.1-36.1-38.7zm59.5-.1c0-15-8.2-27.2-23.5-27.2s-23.5 12.1-23.5 27.2 8.2 27.2 23.5 27.2 23.5-12.2 23.5-27.2zm98.9-37.6v75.3h-11.8l-38.4-56.5v56.5h-12.1v-75.3h13.5l36.8 54.4v-54.4h12zm74.1 58.6h-32.5l-6.4 16.7H263l30.1-75.3h11.1l30.1 75.3h-13.2l-6.4-16.7zm-4.3-11.2l-11.9-30.9-11.9 30.9h23.8zm100.8-47.4l-28 75.3h-11.6l-28.2-75.3h13.4l20.7 57.5 20.7-57.5h13zm60.2 58.6h-32.5l-6.4 16.7h-12.8l30.1-75.3h11.1l30.1 75.3h-13.2l-6.4-16.7zm-4.3-11.2l-11.9-30.9-11.9 30.9h23.8zm-212.4-76c-12.3-.1-23.8-6.2-30.9-16.3l-16.3 16.3-8.6-8.5 19.4-19.4c-.8-3.1-1.3-6.3-1.3-9.5V44.5h12.1v30l39.7-39.8c2.4-2.4 3.7-5.7 3.7-9.2-.1-7.3-6-13.3-13.3-13.5h-12.6v10.8h-12.1V0h24.9c13.9.3 25.1 11.6 25.2 25.6 0 6.5-2.5 12.8-7 17.5l-.1.1-1.3 1.3 4.5 4.5c14.8 14.3 15.2 38 .9 52.8-7.1 7.3-16.8 11.4-26.9 11.4zm-22.2-25c4.6 7.9 13 12.8 22.1 12.9 14 0 25.3-11.3 25.3-25.3 0-6.9-2.8-13.4-7.7-18.2l-.1-.1-4.5-4.5-35.1 35.2z"></path>
    </SVG>
  )
}

const SVG = styled.svg`
  cursor: pointer;

  @media (max-width: 575px) {
    width: 80px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 80px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 80px;
  }
`
