export const getApartmentStatus = (apartmentsStatus, apartmentId) => {
  const apartment = apartmentsStatus.find(
    apartment => apartment.id === apartmentId,
  )
  if (apartment) {
    return apartment.status
  }
  return 'available'
}

export const formatApartmentName = name => {
  let formattedName = ''

  if (name.includes('.')) {
    formattedName = name.split('.')[0]
    return formattedName
  }
  formattedName = name

  return formattedName
}
