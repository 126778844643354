export const initialApartments = {
  1: {
    floor: 1,
    building: 'B',
    apartmentNumber: 811,
    sceneName: 'scene_Lounatuuli_1krs_as811_B',
    sceneName_future: 'scene_Lounatuuli_1krs_as811_B_future',
    name: '811',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 823,
      3: 835,
      4: 845,
      5: 855,
      6: 865,
      7: 875,
    },
  },
  2: {
    floor: 2,
    building: 'B',
    apartmentNumber: 821,
    sceneName: 'scene_Lounatuuli_2krs_as821_B',
    sceneName_future: 'scene_Lounatuuli_2krs_as821_B_future',
    name: '821',
    lookAt: '-38, 0, 120',
    face: 'north',

    relatedApartments: {
      1: 811,
      3: 832,
      4: 842,
      5: 852,
      6: 862,
      7: 872,
    },
  },
  3: {
    floor: 3,
    building: 'B',
    apartmentNumber: 831,
    sceneName: 'scene_Lounatuuli_3krs_as831_B',
    sceneName_future: 'scene_Lounatuuli_3krs_as831_B_future',
    name: '831',
    lookAt: '94.5, 0, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      4: 841,
      5: 851,
      6: 861,
      7: 871,
    },
  },
  4: {
    floor: 4,
    building: 'B',
    apartmentNumber: 841,
    sceneName: 'scene_Lounatuuli_4krs_as841_B',
    sceneName_future: 'scene_Lounatuuli_4krs_as841_B_future',
    name: '841',
    lookAt: '-86, 0, 120',
    face: 'west',

    relatedApartments: {
      2: 821,
      3: 831,
      5: 851,
      6: 861,
      7: 871,
    },
  },
  5: {
    floor: 5,
    building: 'B',
    apartmentNumber: 851,
    sceneName: 'scene_Lounatuuli_5krs_as851_B',
    sceneName_future: 'scene_Lounatuuli_5krs_as851_B_future',
    name: '851',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 821,
      3: 831,
      5: 851,
      6: 861,
      7: 871,
    },
  },
  6: {
    floor: 6,
    building: 'B',
    apartmentNumber: 861,
    sceneName: 'scene_Lounatuuli_6krs_as861_B',
    sceneName_future: 'scene_Lounatuuli_6krs_as861_B_future',
    name: '861',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 821,
      3: 831,
      4: 841,
      5: 851,
      7: 871,
    },
  },
  7: {
    floor: 7,
    building: 'B',
    apartmentNumber: 871,
    sceneName: 'scene_Lounatuuli_7krs_as871_B',
    sceneName_future: 'scene_Lounatuuli_7krs_as871_B_future',
    name: '871',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 821,
      3: 831,
      4: 841,
      5: 851,
      6: 861,
    },
  },
}
