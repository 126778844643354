export const FLOOR_2_DATA = [
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 821,
    sceneName: 'scene_Lounatuuli_2krs_as821_B',
    sceneName_future: 'scene_Lounatuuli_2krs_as821_B_future',
    name: '821',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 811,
      3: 833,
      4: 843,
      5: 853,
      6: 863,
      7: 873,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 822,
    sceneName: 'scene_Lounatuuli_2krs_as822_B',
    sceneName_future: 'scene_Lounatuuli_2krs_as822_B_future',
    name: '822',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 811,
      3: 834,
      4: 844,
      5: 854,
      6: 864,
      7: 874,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 823,
    sceneName: 'scene_Lounatuuli_2krs_as823_B',
    sceneName_future: 'scene_Lounatuuli_2krs_as823_B_future',
    name: '823',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 811,
      3: 835,
      4: 845,
      5: 855,
      6: 865,
      7: 875,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 824,
    sceneName: 'scene_Lounatuuli_2krs_as824_B',
    sceneName_future: 'scene_Lounatuuli_2krs_as824_B_future',
    name: '824',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 812,
      3: 836,
      4: 846,
      5: 856,
      6: 866,
      7: 876,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 825,
    sceneName: 'scene_Lounatuuli_2krs_as825_B',
    sceneName_future: 'scene_Lounatuuli_2krs_as825_B_future',
    name: '825',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 812,
      3: 837,
      4: 847,
      5: 857,
      6: 867,
      7: 877,
    },
  },
]
