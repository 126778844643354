export const FLOOR_7_DATA = [
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 871,
    sceneName: 'scene_Lounatuuli_7krs_as871_B',
    sceneName_future: 'scene_Lounatuuli_7krs_as871_B_future',
    name: '871',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 821,
      3: 831,
      4: 841,
      5: 851,
      6: 861,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 872,
    sceneName: 'scene_Lounatuuli_7krs_as872_B',
    sceneName_future: 'scene_Lounatuuli_7krs_as872_B_future',
    name: '872',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      3: 832,
      4: 842,
      5: 852,
      6: 862,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 873,
    sceneName: 'scene_Lounatuuli_7krs_as873_B',
    sceneName_future: 'scene_Lounatuuli_7krs_as873_B_future',
    name: '873',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      3: 833,
      4: 843,
      5: 853,
      6: 863,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 874,
    sceneName: 'scene_Lounatuuli_7krs_as874_B',
    sceneName_future: 'scene_Lounatuuli_7krs_as874_B_future',
    name: '874',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 822,
      3: 834,
      4: 844,
      5: 854,
      6: 864,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 875,
    sceneName: 'scene_Lounatuuli_7krs_as875_B',
    sceneName_future: 'scene_Lounatuuli_7krs_as875_B_future',
    name: '875',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 823,
      3: 835,
      4: 845,
      5: 855,
      6: 865,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 876,
    sceneName: 'scene_Lounatuuli_7krs_as876_B',
    sceneName_future: 'scene_Lounatuuli_7krs_as876_B_future',
    name: '876',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 824,
      3: 836,
      4: 846,
      5: 856,
      6: 866,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 877,
    sceneName: 'scene_Lounatuuli_7krs_as877_B',
    sceneName_future: 'scene_Lounatuuli_7krs_as877_B_future',
    name: '877',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 824,
      3: 837,
      4: 847,
      5: 857,
      6: 867,
    },
  },
]
