export const FLOOR_3_DATA = [
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 831,
    sceneName: 'scene_Lounatuuli_3krs_as831_B',
    sceneName_future: 'scene_Lounatuuli_3krs_as831_B_future',
    name: '831',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 821,
      4: 841,
      5: 851,
      6: 861,
      7: 871,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 832,
    sceneName: 'scene_Lounatuuli_3krs_as832_B',
    sceneName_future: 'scene_Lounatuuli_3krs_as832_B_future',
    name: '832',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      4: 842,
      5: 852,
      6: 862,
      7: 872,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 833,
    sceneName: 'scene_Lounatuuli_3krs_as833_B',
    sceneName_future: 'scene_Lounatuuli_3krs_as833_B_future',
    name: '833',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      4: 843,
      5: 853,
      6: 863,
      7: 873,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 834,
    sceneName: 'scene_Lounatuuli_3krs_as834_B',
    sceneName_future: 'scene_Lounatuuli_3krs_as834_B_future',
    name: '834',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 822,
      4: 844,
      5: 854,
      6: 864,
      7: 874,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 835,
    sceneName: 'scene_Lounatuuli_3krs_as835_B',
    sceneName_future: 'scene_Lounatuuli_3krs_as835_B_future',
    name: '835',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 823,
      4: 845,
      5: 855,
      6: 865,
      7: 875,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 836,
    sceneName: 'scene_Lounatuuli_3krs_as836_B',
    sceneName_future: 'scene_Lounatuuli_3krs_as836_B_future',
    name: '836',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 824,
      4: 846,
      5: 856,
      6: 866,
      7: 876,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 837,
    sceneName: 'scene_Lounatuuli_3krs_as837_B',
    sceneName_future: 'scene_Lounatuuli_3krs_as837_B_future',
    name: '837',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 824,
      4: 847,
      5: 857,
      6: 867,
      7: 877,
    },
  },
]
