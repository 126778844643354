import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor2({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  apartments,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="rakennus_8"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 250 200"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="329.4002"
          y1="292.2865"
          x2="329.4002"
          y2="247.3765"
          gradientTransform="matrix(1, 0, 0, -1, -164.9032, 361.3841)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="329.4002"
          y1="262.5381"
          x2="329.4002"
          y2="217.6281"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="329.4002"
          y1="219.4217"
          x2="329.4002"
          y2="174.5117"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="329.4002"
          y1="188.4726"
          x2="329.4002"
          y2="143.5626"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="211.5426"
          y1="226.207"
          x2="211.5426"
          y2="181.2969"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <rect className="cls-1" width="250" height="200" />
        <path
          id="bonava"
          className="cls-2"
          d="M195.6822,171.4479a1.45,1.45,0,0,0,.93-1.379c0-.9465-.7506-1.55-1.8767-1.55h-2.7824V174.54h2.7661c1.15,0,2.0318-.62,2.0318-1.6727A1.4165,1.4165,0,0,0,195.6822,171.4479Zm-2.7416-2.0236h1.6972c.563,0,.9792.2774.9792.8159s-.4325.8486-1.02.8486h-1.6564Zm1.7788,4.21h-1.7788v-1.6891l1.7706-.0082c.62,0,1.0445.2938,1.0445.84C195.7557,173.3409,195.3069,173.6347,194.7194,173.6347Zm6.6914-5.2059a3.1091,3.1091,0,1,0,2.88,3.0925A2.8707,2.8707,0,0,0,201.4108,168.4288Zm0,5.2793a2.2029,2.2029,0,1,1,1.8767-2.1786A1.9314,1.9314,0,0,1,201.4108,173.7081Zm8.8211-.84-2.9457-4.3491h-1.0771V174.54h.971v-4.52l3.0681,4.52h.9465v-6.0218h-.9628Zm5.1565-4.3491-2.4072,6.0218h1.02l.5141-1.3381h2.6029l.5141,1.3381h1.0526l-2.4071-6.0218Zm-.5222,3.7942.9547-2.4724.9546,2.4724Zm7.278.8078-1.6564-4.602h-1.069l2.2522,6.0218h.93l2.2358-6.0218h-1.0363Zm6.6661-4.602h-.8894l-2.4071,6.0218h1.02l.514-1.3381h2.603l.514,1.3381h1.0526Zm-1.4116,3.7942.9547-2.4724.9547,2.4724Zm-12.0926-9.0676a2.9637,2.9637,0,0,0-.91-2.1472l-.3559-.357.1048-.1048.0115-.0119a1.9923,1.9923,0,0,0,.5591-1.3982,2.0643,2.0643,0,0,0-2.0175-2.0453l-1.9941,0V159.01h.9675v-.8616h1.011a1.0914,1.0914,0,0,1,1.0655,1.0778,1.0252,1.0252,0,0,1-.2935.7322l-3.1786,3.1791v-2.3962h-.9676v2.504a2.8648,2.8648,0,0,0,.1027.7574l-1.5465,1.5467.6843.6841,1.3021-1.3022a3.0392,3.0392,0,0,0,2.4669,1.3028A2.9919,2.9919,0,0,0,215.3061,163.2452Zm-2.9882,2.0212a2.07,2.07,0,0,1-1.7706-1.0317l2.8088-2.8093.3558.3567.008.0079a2.0211,2.0211,0,0,1-1.402,3.4764Z"
        />
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(2, 824)}
          onMouseOver={() => setElementOnHover('824')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_2krs_as824_B'
                ? 'active-apt'
                : elementOnHover === '824'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '824') === 'sold' ? 'bg-gray' : ''
            }`}
            points="171.534 188.467 53.368 188.467 53.368 166.467 91.534 166.467 91.534 135.2 103.055 135.2 103.055 150.138 171.534 150.138 171.534 188.467"
          />
        </g>
        <g
          onClick={() => changeView(2, 823)}
          onMouseOver={() => setElementOnHover('823')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_2krs_as823_B'
                ? 'active-apt'
                : elementOnHover === '823'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '823') === 'sold' ? 'bg-gray' : ''
            }`}
            points="103.055 120.64 116.034 120.64 116.034 108.55 158.23 108.55 158.23 124.578 171.534 124.578 171.534 150.138 103.055 150.138 103.055 120.64"
          />
        </g>
        <g
          onClick={() => changeView(2, 822)}
          onMouseOver={() => setElementOnHover('822')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_2krs_as822_B'
                ? 'active-apt'
                : elementOnHover === '822'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '822') === 'sold' ? 'bg-gray' : ''
            }`}
            x="102.8678"
            y="80.3556"
            width="68.5694"
            height="28.1944"
          />
        </g>
        <g
          onClick={() => changeView(2, 821)}
          onMouseOver={() => setElementOnHover('821')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_2krs_as821_B'
                ? 'active-apt'
                : elementOnHover === '821'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '821') === 'sold' ? 'bg-gray' : ''
            }`}
            points="103.034 51.633 122.201 51.633 122.201 32.8 137.295 32.8 137.295 19.3 171.534 19.3 171.534 80.356 102.868 80.356 103.034 51.633"
          />
        </g>
        <g
          onClick={() => changeView(2, 825)}
          onMouseOver={() => setElementOnHover('825')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_2krs_as825_B'
                ? 'active-apt'
                : elementOnHover === '825'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '825') === 'sold' ? 'bg-gray' : ''
            }`}
            points="91.534 110.467 91.534 166.467 53.368 166.467 53.368 149.967 40.868 149.967 40.868 111.911 53.368 111.911 53.368 110.467 91.534 110.467"
          />
        </g>
      </g>
      <g id="levelfloor">
        <g>
          <rect
            className="cls-4"
            x="53.2017"
            y="12.8"
            width="105.0285"
            height="175.6667"
          />
          <polyline
            className="cls-5"
            points="40.868 111.911 53.202 111.911 53.202 150.138 40.868 150.138"
          />
          <polyline
            className="cls-5"
            points="171.535 19.3 158.23 19.3 158.23 51.467 171.535 51.467"
          />
          <polyline
            className="cls-5"
            points="171.535 80.356 158.23 80.356 158.23 51.467 171.535 51.467"
          />
          <polyline
            className="cls-5"
            points="171.535 108.55 158.23 108.55 158.23 80.356 171.535 80.356"
          />
          <polyline
            className="cls-5"
            points="158.23 169.911 158.23 188.467 171.535 188.467"
          />
          <polyline
            className="cls-5"
            points="158.23 169.911 158.23 150.138 171.535 150.138"
          />
          <polyline
            className="cls-5"
            points="171.535 150.138 158.23 150.138 158.23 124.578 171.535 124.578"
          />
          <polygon
            className="cls-5"
            points="91.535 135.2 91.535 110.467 53.202 110.467 53.202 80.356 91.535 80.356 91.535 51.467 103.055 51.467 103.055 120.64 103.055 135.2 91.535 135.2"
          />
          <polyline
            className="cls-5"
            points="103.055 120.64 116.035 120.717 116.035 108.55 103.055 108.55"
          />
          <g>
            <line
              className="cls-5"
              x1="91.9378"
              y1="101.735"
              x2="78.278"
              y2="101.735"
            />
            <polyline
              className="cls-5"
              points="91.938 92.802 60.938 92.802 60.938 110.818"
            />
          </g>
          <polyline
            className="cls-5"
            points="53.202 166.467 91.535 166.467 91.535 135.2"
          />
          <polyline
            className="cls-5"
            points="103.055 135.2 103.055 150.138 158.23 150.138"
          />
          <line
            className="cls-5"
            x1="116.035"
            y1="108.55"
            x2="158.2302"
            y2="108.55"
          />
          <line
            className="cls-5"
            x1="158.2302"
            y1="80.3556"
            x2="103.0554"
            y2="80.3556"
          />
          <polyline
            className="cls-5"
            points="158.23 19.3 137.868 19.3 137.868 32.8 122.535 32.8 122.535 51.467 103.055 51.467"
          />
          <polyline
            className="cls-4"
            points="25.702 196.142 25.702 188.467 53.202 188.467"
          />
          <line
            className="cls-4"
            x1="118.8683"
            y1="196.1416"
            x2="118.8683"
            y2="188.4667"
          />
          <line
            className="cls-6"
            x1="41.0032"
            y1="111.4944"
            x2="41.0221"
            y2="150.5621"
          />
          <line
            className="cls-6"
            x1="171.3849"
            y1="19.3"
            x2="171.4897"
            y2="108.9771"
          />
          <line
            className="cls-6"
            x1="171.5345"
            y1="124.175"
            x2="171.5345"
            y2="188.8556"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 821 && (
          <g id="_821" data-name="821">
            <path
              id="cone"
              className="cls-10"
              d="M167.872,69.1176a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 822 && (
          <g id="_822" data-name="822">
            <path
              id="cone-2"
              data-name="cone"
              className="cls-11"
              d="M167.872,98.8659a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-2"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 823 && (
          <g id="_823" data-name="823">
            <path
              id="cone-3"
              data-name="cone"
              className="cls-12"
              d="M167.872,141.9823a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-3"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 824 && (
          <g id="_824" data-name="824">
            <path
              id="cone-4"
              data-name="cone"
              className="cls-13"
              d="M167.872,172.9314a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-4"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 825 && (
          <g id="_825" data-name="825">
            <path
              id="cone-5"
              data-name="cone"
              className="cls-14"
              d="M50.0144,135.1971a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-5"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
      </g>
      <g id="elevator">
        <path
          id="plate"
          className="cls-2"
          d="M212.7418,56.73a15.1463,15.1463,0,0,0-15.15,15.15v56.24a15.15,15.15,0,1,0,30.3,0V71.88A15.1463,15.1463,0,0,0,212.7418,56.73Z"
        />
        <path
          className="cls-7"
          d="M212.7418,88.48a11.52,11.52,0,1,0,11.52,11.52A11.523,11.523,0,0,0,212.7418,88.48Z"
        />
        <path
          className="cls-8"
          d="M209.7948,104.2229v-.5938l.7588-.7373c1.8261-1.7383,2.6513-2.6621,2.663-3.74a1.2761,1.2761,0,0,0-1.42-1.397,2.436,2.436,0,0,0-1.5176.605l-.3085-.6822a3.1388,3.1388,0,0,1,2.0244-.726,2.0122,2.0122,0,0,1,2.1894,2.0791c0,1.32-.957,2.3877-2.4648,3.84l-.5713.5274v.0224H214.36v.8028Z"
        />
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-9"
            d="M212.7368,135.17a6.89,6.89,0,1,1,0-13.78,6.89,6.89,0,1,1,0,13.78Z"
          />
          <path
            className="cls-9"
            d="M212.7368,121.39a6.89,6.89,0,1,0,0,13.78,6.89,6.89,0,1,0,0-13.78Z"
          />
          <path
            className="cls-2"
            d="M217.2418,126.955l.3975.53-4.505,3.0475a.403.403,0,0,1-.53,0l-4.5049-3.0475.3975-.53,4.24,2.7825a.2484.2484,0,0,0,.3975,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-9"
            d="M212.7368,64.815a6.89,6.89,0,1,0,0,13.78,6.89,6.89,0,1,0,0-13.78Z"
          />
          <path
            className="cls-9"
            d="M212.7368,78.595a6.89,6.89,0,1,1,0-13.78,6.89,6.89,0,1,1,0,13.78Z"
          />
          <path
            className="cls-2"
            d="M217.2418,73.03l.3975-.53-4.505-3.0475a.403.403,0,0,0-.53,0L208.0994,72.5l.3975.53,4.24-2.7825a.2484.2484,0,0,1,.3975,0Z"
          />
        </g>
      </g>
      <g id="apartmentnr">
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_2krs_as824_B'
              ? 'white-text'
              : elementOnHover === '824'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '824') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 824)}
          onMouseOver={() => setElementOnHover('824')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M127.76,171.7561a1.3019,1.3019,0,0,1,.9033-1.2188l-.0068-.0215a1.1283,1.1283,0,0,1-.7217-1.0224,1.2754,1.2754,0,0,1,1.4082-1.212,1.1754,1.1754,0,0,1,1.3233,1.1211,1.1564,1.1564,0,0,1-.7491,1.0645v.0215a1.2614,1.2614,0,0,1,.918,1.1758,1.4023,1.4023,0,0,1-1.5478,1.3242C128.3338,172.9885,127.76,172.4211,127.76,171.7561Zm2.4512-.0284c0-.5537-.3848-.8193-1.001-.9941a.9881.9881,0,0,0-.8193.9385.8413.8413,0,0,0,.91.8681A.8241.8241,0,0,0,130.2108,171.7277Zm-1.6944-2.2763c0,.455.3428.7.8682.8408a.8972.8972,0,0,0,.6934-.8272.7072.7072,0,0,0-.77-.7421A.7239.7239,0,0,0,128.5164,169.4514Z"
          />
          <path
            className="cls-2"
            d="M131.4061,172.9113v-.3779l.4834-.47c1.1631-1.1065,1.6885-1.6944,1.6953-2.3809a.8122.8122,0,0,0-.9033-.89,1.5526,1.5526,0,0,0-.9668.3847l-.1963-.4336a1.9968,1.9968,0,0,1,1.2891-.4629,1.2812,1.2812,0,0,1,1.3935,1.3243c0,.8408-.6094,1.5195-1.5683,2.4443l-.3643.3359V172.4h2.0449v.5117Z"
          />
          <path
            className="cls-2"
            d="M136.9022,172.9113v-1.2392h-2.1143v-.4063l2.03-2.9072h.666v2.83h.6367v.4834h-.6367v1.2392Zm0-1.7226v-1.52c0-.2373.0078-.4756.0215-.7139h-.0215c-.14.2656-.252.4619-.3779.6719l-1.1133,1.5478v.0147Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_2krs_as825_B'
              ? 'white-text'
              : elementOnHover === '825'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '825') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 825)}
          onMouseOver={() => setElementOnHover('825')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M66.0091,139.0109a1.3012,1.3012,0,0,1,.9033-1.2187l-.0068-.0215a1.1275,1.1275,0,0,1-.7217-1.0225,1.2752,1.2752,0,0,1,1.4077-1.2119,1.1757,1.1757,0,0,1,1.3238,1.1211,1.1572,1.1572,0,0,1-.749,1.0645v.0215a1.2616,1.2616,0,0,1,.9174,1.1757,1.4024,1.4024,0,0,1-1.5478,1.3243C66.5833,140.2434,66.0091,139.676,66.0091,139.0109Zm2.4512-.0283c0-.5537-.3853-.8193-1.0015-.9941a.9881.9881,0,0,0-.8193.9385.8416.8416,0,0,0,.91.8681A.8242.8242,0,0,0,68.46,138.9826Zm-1.6948-2.2763c0,.455.3432.7.8681.8408a.8977.8977,0,0,0,.6939-.8272.7076.7076,0,0,0-.77-.7422A.7241.7241,0,0,0,66.7655,136.7063Z"
          />
          <path
            className="cls-2"
            d="M69.6556,140.1662v-.3779l.4834-.47c1.1626-1.1065,1.688-1.6944,1.6948-2.3809a.812.812,0,0,0-.9033-.89,1.5512,1.5512,0,0,0-.9663.3848l-.1963-.4336a1.9946,1.9946,0,0,1,1.2886-.4629,1.2815,1.2815,0,0,1,1.394,1.3242c0,.8409-.6093,1.52-1.5688,2.4444l-.3643.3359v.0137h2.0454v.5117Z"
          />
          <path
            className="cls-2"
            d="M75.9642,136.132H74.2279l-.1758,1.17a2.3484,2.3484,0,0,1,.3721-.0284,1.9262,1.9262,0,0,1,.98.2452,1.3,1.3,0,0,1,.6514,1.17,1.58,1.58,0,0,1-1.6953,1.5547,2.2365,2.2365,0,0,1-1.1348-.28l.1543-.4688a2.049,2.049,0,0,0,.9737.252,1.0048,1.0048,0,0,0,1.0781-.9883c-.0069-.5879-.3985-1.0078-1.31-1.0078a4.63,4.63,0,0,0-.63.0488l.294-2.1855h2.1777Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_2krs_as823_B'
              ? 'white-text'
              : elementOnHover === '823'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '823') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 823)}
          onMouseOver={() => setElementOnHover('823')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M127.3748,131.7932a1.3018,1.3018,0,0,1,.9034-1.2188l-.0069-.0215a1.1281,1.1281,0,0,1-.7216-1.0224,1.2753,1.2753,0,0,1,1.4082-1.2119,1.1752,1.1752,0,0,1,1.3232,1.1211,1.1564,1.1564,0,0,1-.749,1.0644v.0215a1.2613,1.2613,0,0,1,.9179,1.1758,1.4023,1.4023,0,0,1-1.5478,1.3242C127.9491,133.0256,127.3748,132.4582,127.3748,131.7932Zm2.4512-.0284c0-.5537-.3847-.8193-1.001-.9941a.9881.9881,0,0,0-.8193.9385.8414.8414,0,0,0,.91.8681A.824.824,0,0,0,129.826,131.7648Zm-1.6943-2.2763c0,.4551.3428.7.8681.8408a.8972.8972,0,0,0,.6934-.8271.7073.7073,0,0,0-.77-.7422A.7239.7239,0,0,0,128.1317,129.4885Z"
          />
          <path
            className="cls-2"
            d="M131.0213,132.9484v-.3779l.4834-.47c1.1631-1.1065,1.6885-1.6944,1.6953-2.3809a.8122.8122,0,0,0-.9033-.89,1.5526,1.5526,0,0,0-.9668.3847l-.1963-.4336a1.997,1.997,0,0,1,1.2891-.4628,1.2812,1.2812,0,0,1,1.3936,1.3242c0,.8408-.6094,1.5195-1.5684,2.4443l-.3643.3359v.0137h2.045v.5117Z"
          />
          <path
            className="cls-2"
            d="M134.76,132.2414a2.0734,2.0734,0,0,0,1.0088.2871c.792,0,1.0371-.5049,1.0293-.8828-.0068-.6377-.5811-.91-1.1768-.91h-.3427v-.4628h.3427c.4483,0,1.0157-.23,1.0157-.77,0-.3643-.23-.6856-.7979-.6856a1.6778,1.6778,0,0,0-.9111.3008l-.16-.4483a2.1635,2.1635,0,0,1,1.19-.35c.8964,0,1.3027.5322,1.3027,1.0859a1.13,1.13,0,0,1-.8408,1.0713v.0146a1.1851,1.1851,0,0,1,1.0156,1.169c0,.7285-.5674,1.3662-1.66,1.3662a2.3128,2.3128,0,0,1-1.1836-.3086Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_2krs_as822_B'
              ? 'white-text'
              : elementOnHover === '822'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '822') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 822)}
          onMouseOver={() => setElementOnHover('822')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M127.3748,96.4733a1.3018,1.3018,0,0,1,.9034-1.2187l-.0069-.0215a1.1282,1.1282,0,0,1-.7216-1.0225,1.2753,1.2753,0,0,1,1.4082-1.2119,1.1752,1.1752,0,0,1,1.3232,1.1211,1.1564,1.1564,0,0,1-.749,1.0645v.0215a1.2613,1.2613,0,0,1,.9179,1.1757,1.4024,1.4024,0,0,1-1.5478,1.3243C127.9491,97.7058,127.3748,97.1384,127.3748,96.4733Zm2.4512-.0283c0-.5537-.3847-.8193-1.001-.9941a.9881.9881,0,0,0-.8193.9385.8414.8414,0,0,0,.91.8681A.824.824,0,0,0,129.826,96.445Zm-1.6943-2.2763c0,.455.3428.7.8681.8408a.8972.8972,0,0,0,.6934-.8272.7073.7073,0,0,0-.77-.7422A.724.724,0,0,0,128.1317,94.1687Z"
          />
          <path
            className="cls-2"
            d="M131.0213,97.6286v-.3779l.4834-.47c1.1631-1.1065,1.6885-1.6944,1.6953-2.3809a.8122.8122,0,0,0-.9033-.89,1.5526,1.5526,0,0,0-.9668.3847l-.1963-.4336a1.997,1.997,0,0,1,1.2891-.4629,1.2812,1.2812,0,0,1,1.3936,1.3243c0,.8408-.6094,1.52-1.5684,2.4443l-.3643.3359v.0137h2.045v.5117Z"
          />
          <path
            className="cls-2"
            d="M134.6131,97.6286v-.3779l.4834-.47c1.1621-1.1065,1.6875-1.6944,1.6944-2.3809a.8118.8118,0,0,0-.9034-.89,1.5529,1.5529,0,0,0-.9668.3847l-.1962-.4336a1.9965,1.9965,0,0,1,1.289-.4629,1.2812,1.2812,0,0,1,1.3936,1.3243c0,.8408-.6094,1.52-1.5684,2.4443l-.3642.3359v.0137h2.0449v.5117Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_2krs_as821_B'
              ? 'white-text'
              : elementOnHover === '821'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '821') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 821)}
          onMouseOver={() => setElementOnHover('821')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M127.3748,67.0588a1.3019,1.3019,0,0,1,.9034-1.2188l-.0069-.0214a1.1282,1.1282,0,0,1-.7216-1.0225,1.2753,1.2753,0,0,1,1.4082-1.2119,1.1752,1.1752,0,0,1,1.3232,1.1211,1.1564,1.1564,0,0,1-.749,1.0644v.0215a1.2614,1.2614,0,0,1,.9179,1.1758,1.4023,1.4023,0,0,1-1.5478,1.3242C127.9491,68.2912,127.3748,67.7238,127.3748,67.0588Zm2.4512-.0283c0-.5537-.3847-.8194-1.001-.9942a.9882.9882,0,0,0-.8193.9385.8414.8414,0,0,0,.91.8682A.8241.8241,0,0,0,129.826,67.0305Zm-1.6943-2.2764c0,.4551.3428.7.8681.8408a.8972.8972,0,0,0,.6934-.8271.7073.7073,0,0,0-.77-.7422A.7239.7239,0,0,0,128.1317,64.7541Z"
          />
          <path
            className="cls-2"
            d="M131.0213,68.2141v-.378l.4834-.47c1.1631-1.1064,1.6885-1.6943,1.6953-2.3809a.8122.8122,0,0,0-.9033-.89,1.5527,1.5527,0,0,0-.9668.3848l-.1963-.4336a1.997,1.997,0,0,1,1.2891-.4629,1.2812,1.2812,0,0,1,1.3936,1.3242c0,.8408-.6094,1.5195-1.5684,2.4443l-.3643.336v.0136h2.045v.5118Z"
          />
          <path
            className="cls-2"
            d="M135.951,64.2355h-.0146l-.791.4278-.1192-.47.9942-.5323h.5253v4.5528h-.5947Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  821: [165.102, 64.5176],
  822: [165.102, 94.2659],
  823: [165.102, 137.3823],
  824: [165.102, 168.3314],
  825: [47.2444, 130.5971],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #86cfc2;
  }

  .cls-2,
  .cls-8 {
    fill: #004a33;
  }

  .cls-3 {
    fill: #537f6e;
  }

  .cls-4,
  .cls-5,
  .cls-6 {
    fill: none;
  }

  .cls-4,
  .cls-5,
  .cls-6,
  .cls-8 {
    stroke: #004a33;
    stroke-miterlimit: 10;
  }

  .cls-4 {
    stroke-width: 1.5px;
  }

  .cls-5 {
    stroke-width: 0.85px;
  }

  .cls-6 {
    stroke-width: 0.3px;
  }

  .cls-7 {
    fill: #a5dacf;
  }

  .cls-8 {
    stroke-width: 0.75px;
  }

  .cls-9 {
    fill: #65c5b4;
  }

  .cls-10 {
    fill: url(#linear-gradient);
  }

  .cls-11 {
    fill: url(#linear-gradient-2);
  }

  .cls-12 {
    fill: url(#linear-gradient-3);
  }

  .cls-13 {
    fill: url(#linear-gradient-4);
  }

  .cls-14 {
    fill: url(#linear-gradient-5);
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .cls-9 {
      fill: var(--secondary-color);
    }

    .cls-2 {
      fill: var(--green-color);
    }
  }

  .disable-lift-btn {
    cursor: not-allowed;

    .cls-1 {
      fill: var(--grey);
    }

    .cls-6 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--primary-color);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text .cls-2 {
    fill: var(--light);
    cursor: pointer;
  }

  .green-text .cls-2 {
    fill: var(--green-color);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white-sold .cls-6 {
    fill: #fff;
    cursor: pointer;
  }
`
