import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor3({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  apartments,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="rakennus_8"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 250 200"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="211.5426"
          y1="286.2107"
          x2="211.5426"
          y2="241.3007"
          gradientTransform="matrix(1, 0, 0, -1, -164.9032, 361.3841)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="329.4002"
          y1="321.2543"
          x2="329.4002"
          y2="276.3442"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="329.4002"
          y1="292.2865"
          x2="329.4002"
          y2="247.3765"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="329.4002"
          y1="262.5381"
          x2="329.4002"
          y2="217.6281"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="329.4002"
          y1="219.4217"
          x2="329.4002"
          y2="174.5117"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="329.4002"
          y1="188.4726"
          x2="329.4002"
          y2="143.5626"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="211.5426"
          y1="226.207"
          x2="211.5426"
          y2="181.2969"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <rect className="cls-1" width="250" height="200" />
        <path
          id="bonava"
          className="cls-2"
          d="M195.6822,171.4479a1.45,1.45,0,0,0,.93-1.379c0-.9465-.7506-1.55-1.8767-1.55h-2.7824V174.54h2.7661c1.15,0,2.0318-.62,2.0318-1.6727A1.4165,1.4165,0,0,0,195.6822,171.4479Zm-2.7416-2.0236h1.6972c.563,0,.9792.2774.9792.8159s-.4325.8486-1.02.8486h-1.6564Zm1.7788,4.21h-1.7788v-1.6891l1.7706-.0082c.62,0,1.0445.2938,1.0445.84C195.7557,173.3409,195.3069,173.6347,194.7194,173.6347Zm6.6914-5.2059a3.1091,3.1091,0,1,0,2.88,3.0925A2.8707,2.8707,0,0,0,201.4108,168.4288Zm0,5.2793a2.2029,2.2029,0,1,1,1.8767-2.1786A1.9314,1.9314,0,0,1,201.4108,173.7081Zm8.8211-.84-2.9457-4.3491h-1.0771V174.54h.971v-4.52l3.0681,4.52h.9465v-6.0218h-.9628Zm5.1565-4.3491-2.4072,6.0218h1.02l.5141-1.3381h2.6029l.5141,1.3381h1.0526l-2.4071-6.0218Zm-.5222,3.7942.9547-2.4724.9546,2.4724Zm7.278.8078-1.6564-4.602h-1.069l2.2522,6.0218h.93l2.2358-6.0218h-1.0363Zm6.6661-4.602h-.8894l-2.4071,6.0218h1.02l.514-1.3381h2.603l.514,1.3381h1.0526Zm-1.4116,3.7942.9547-2.4724.9547,2.4724Zm-12.0926-9.0676a2.9637,2.9637,0,0,0-.91-2.1472l-.3559-.357.1048-.1048.0115-.0119a1.9923,1.9923,0,0,0,.5591-1.3982,2.0643,2.0643,0,0,0-2.0175-2.0453l-1.9941,0V159.01h.9675v-.8616h1.011a1.0914,1.0914,0,0,1,1.0655,1.0778,1.0252,1.0252,0,0,1-.2935.7322l-3.1786,3.1791v-2.3962h-.9676v2.504a2.8648,2.8648,0,0,0,.1027.7574l-1.5465,1.5467.6843.6841,1.3021-1.3022a3.0392,3.0392,0,0,0,2.4669,1.3028A2.9919,2.9919,0,0,0,215.3061,163.2452Zm-2.9882,2.0212a2.07,2.07,0,0,1-1.7706-1.0317l2.8088-2.8093.3558.3567.008.0079a2.0211,2.0211,0,0,1-1.402,3.4764Z"
        />
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(3, 832)}
          onMouseOver={() => setElementOnHover('832')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_3krs_as832_B'
                ? 'active-apt'
                : elementOnHover === '832'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '832') === 'sold' ? 'bg-gray' : ''
            }`}
            points="53.368 12.8 157.868 12.8 157.868 19.633 171.534 19.633 171.534 51.467 103.034 51.467 103.034 65.244 91.534 65.244 91.534 35.967 53.201 35.967 53.368 12.8"
          />
        </g>
        <g
          onClick={() => changeView(3, 833)}
          onMouseOver={() => setElementOnHover('833')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_3krs_as833_B'
                ? 'active-apt'
                : elementOnHover === '833'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '833') === 'sold' ? 'bg-gray' : ''
            }`}
            x="102.8678"
            y="51.4667"
            width="68.6667"
            height="28.8889"
          />
        </g>
        <g
          onClick={() => changeView(3, 834)}
          onMouseOver={() => setElementOnHover('834')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_3krs_as834_B'
                ? 'active-apt'
                : elementOnHover === '834'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '834') === 'sold' ? 'bg-gray' : ''
            }`}
            x="102.8678"
            y="80.3556"
            width="68.6667"
            height="28.1944"
          />
        </g>
        <g
          onClick={() => changeView(3, 835)}
          onMouseOver={() => setElementOnHover('835')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_3krs_as835_B'
                ? 'active-apt'
                : elementOnHover === '835'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '835') === 'sold' ? 'bg-gray' : ''
            }`}
            points="103.055 120.64 116.034 120.64 116.034 108.55 158.23 108.55 158.23 124.578 171.534 124.578 171.534 150.138 103.055 150.138 103.055 120.64"
          />
        </g>
        <g
          onClick={() => changeView(3, 836)}
          onMouseOver={() => setElementOnHover('836')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_3krs_as836_B'
                ? 'active-apt'
                : elementOnHover === '836'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '836') === 'sold' ? 'bg-gray' : ''
            }`}
            points="53.368 188.467 53.368 166.467 91.534 166.467 91.534 135.2 103.055 135.2 103.055 150.138 171.534 150.138 171.534 188.467 53.368 188.467"
          />
        </g>
        <g
          onClick={() => changeView(3, 837)}
          onMouseOver={() => setElementOnHover('837')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_3krs_as837_B'
                ? 'active-apt'
                : elementOnHover === '837'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '837') === 'sold' ? 'bg-gray' : ''
            }`}
            points="40.868 111.911 53.368 111.911 53.368 110.467 91.534 110.467 91.534 166.467 53.368 166.467 53.368 149.967 40.868 149.967 40.868 111.911"
          />
        </g>
        <g
          onClick={() => changeView(3, 831)}
          onMouseOver={() => setElementOnHover('831')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_3krs_as831_B'
                ? 'active-apt'
                : elementOnHover === '831'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '831') === 'sold' ? 'bg-gray' : ''
            }`}
            points="53.201 52.8 53.201 35.967 91.534 35.967 91.534 92.802 53.368 92.802 53.368 90.98 40.868 90.98 40.868 52.753 53.201 52.8"
          />
        </g>
      </g>
      <g id="levelfloor">
        <rect
          className="cls-4"
          x="53.2012"
          y="12.8"
          width="105.0285"
          height="175.6667"
        />
        <polyline
          className="cls-5"
          points="40.868 111.911 53.201 111.911 53.201 150.138 40.868 150.138"
        />
        <polyline
          className="cls-5"
          points="40.868 52.8 53.201 52.8 53.201 91.027 40.868 91.027"
        />
        <polyline
          className="cls-5"
          points="171.534 19.3 158.23 19.3 158.23 51.467 171.534 51.467"
        />
        <polyline
          className="cls-5"
          points="171.534 80.356 158.23 80.356 158.23 51.467 171.534 51.467"
        />
        <polyline
          className="cls-5"
          points="171.534 108.55 158.23 108.55 158.23 80.356 171.534 80.356"
        />
        <polyline
          className="cls-5"
          points="158.23 169.911 158.23 188.467 171.534 188.467"
        />
        <polyline
          className="cls-5"
          points="158.23 169.911 158.23 150.138 171.534 150.138"
        />
        <polyline
          className="cls-5"
          points="171.534 150.138 158.23 150.138 158.23 124.578 171.534 124.578"
        />
        <line
          className="cls-6"
          x1="41.0178"
          y1="52.7529"
          x2="41.0178"
          y2="90.98"
        />
        <line
          className="cls-6"
          x1="41.0032"
          y1="111.4944"
          x2="41.0221"
          y2="150.5621"
        />
        <line
          className="cls-6"
          x1="171.3849"
          y1="19.3"
          x2="171.4897"
          y2="108.9771"
        />
        <line
          className="cls-6"
          x1="171.5345"
          y1="124.175"
          x2="171.5345"
          y2="188.8556"
        />
        <polygon
          className="cls-5"
          points="91.534 135.2 91.534 110.467 53.201 110.467 53.201 92.802 91.534 92.802 91.534 65.244 103.055 65.244 103.055 120.64 103.055 135.2 91.534 135.2"
        />
        <polyline
          className="cls-5"
          points="103.055 120.64 116.034 120.717 116.034 108.55 103.055 108.55"
        />
        <g>
          <line
            className="cls-5"
            x1="91.9373"
            y1="101.735"
            x2="78.2775"
            y2="101.735"
          />
          <polyline
            className="cls-5"
            points="91.937 92.802 60.937 92.802 60.937 110.818"
          />
        </g>
        <polyline
          className="cls-5"
          points="53.201 166.467 91.534 166.467 91.534 135.2"
        />
        <polyline
          className="cls-5"
          points="103.055 135.2 103.055 150.138 158.23 150.138"
        />
        <line
          className="cls-5"
          x1="116.0345"
          y1="108.55"
          x2="158.2297"
          y2="108.55"
        />
        <line
          className="cls-5"
          x1="158.2297"
          y1="80.3556"
          x2="103.0549"
          y2="80.3556"
        />
        <polyline
          className="cls-5"
          points="53.201 36.022 91.534 36.022 91.534 65.244"
        />
        <polyline
          className="cls-5"
          points="103.055 65.244 103.055 51.467 158.23 51.467"
        />
        <polyline
          className="cls-4"
          points="25.701 196.142 25.701 188.467 53.201 188.467"
        />
        <line
          className="cls-4"
          x1="118.8678"
          y1="196.1416"
          x2="118.8678"
          y2="188.4667"
        />
      </g>
      <g id="view">
        {apartmentNumber === 831 && (
          <g id="_831" data-name="831">
            <path
              id="cone"
              className="cls-10"
              d="M50.0144,75.1933a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 832 && (
          <g id="_832" data-name="832">
            <path
              id="cone-2"
              data-name="cone"
              className="cls-11"
              d="M167.872,40.15a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-2"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 833 && (
          <g id="_833" data-name="833">
            <path
              id="cone-3"
              data-name="cone"
              className="cls-12"
              d="M167.872,69.1176a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-3"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 834 && (
          <g id="_834" data-name="834">
            <path
              id="cone-4"
              data-name="cone"
              className="cls-13"
              d="M167.872,98.8659a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-4"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 835 && (
          <g id="_835" data-name="835">
            <path
              id="cone-5"
              data-name="cone"
              className="cls-14"
              d="M167.872,141.9823a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-5"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 836 && (
          <g id="_836" data-name="836">
            <path
              id="cone-6"
              data-name="cone"
              className="cls-15"
              d="M167.872,172.9314a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-6"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 837 && (
          <g id="_837" data-name="837">
            <path
              id="cone-7"
              data-name="cone"
              className="cls-16"
              d="M50.0144,135.1971a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-7"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
      </g>
      <g id="elevator">
        <path
          id="plate"
          className="cls-2"
          d="M212.7418,56.73a15.1463,15.1463,0,0,0-15.15,15.15v56.24a15.15,15.15,0,1,0,30.3,0V71.88A15.1463,15.1463,0,0,0,212.7418,56.73Z"
        />
        <g id="_5" data-name="5">
          <path
            className="cls-7"
            d="M212.7418,88.48a11.52,11.52,0,1,0,11.52,11.52A11.523,11.523,0,0,0,212.7418,88.48Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-8"
            d="M212.7368,135.17a6.89,6.89,0,1,1,0-13.78,6.89,6.89,0,1,1,0,13.78Z"
          />
          <path
            className="cls-8"
            d="M212.7368,121.39a6.89,6.89,0,1,0,0,13.78,6.89,6.89,0,1,0,0-13.78Z"
          />
          <path
            className="cls-2"
            d="M217.2418,126.955l.3975.53-4.505,3.0475a.403.403,0,0,1-.53,0l-4.5049-3.0475.3975-.53,4.24,2.7825a.2484.2484,0,0,0,.3975,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-8"
            d="M212.7368,64.815a6.89,6.89,0,1,0,0,13.78,6.89,6.89,0,1,0,0-13.78Z"
          />
          <path
            className="cls-8"
            d="M212.7368,78.595a6.89,6.89,0,1,1,0-13.78,6.89,6.89,0,1,1,0,13.78Z"
          />
          <path
            className="cls-2"
            d="M217.2418,73.03l.3975-.53-4.505-3.0475a.403.403,0,0,0-.53,0L208.0994,72.5l.3975.53,4.24-2.7825a.2484.2484,0,0,1,.3975,0Z"
          />
        </g>
        <path
          className="cls-9"
          d="M210.0262,102.966a3.225,3.225,0,0,0,1.584.4512c1.2432,0,1.6279-.792,1.6172-1.3858-.0108-1.0009-.9131-1.4306-1.8486-1.4306H210.84v-.7261h.5391c.7041,0,1.5957-.3628,1.5957-1.21,0-.5722-.3633-1.0781-1.2549-1.0781a2.6284,2.6284,0,0,0-1.43.4727l-.2529-.7041a3.3987,3.3987,0,0,1,1.87-.55c1.4082,0,2.0459.8359,2.0459,1.7051a1.7746,1.7746,0,0,1-1.32,1.6831v.0224a1.8622,1.8622,0,0,1,1.5957,1.8369c0,1.1446-.8916,2.1455-2.6075,2.1455a3.6339,3.6339,0,0,1-1.8593-.4843Z"
        />
      </g>
      <g id="apartmentnr">
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_3krs_as836_B'
              ? 'white-text'
              : elementOnHover === '836'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '836') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(3, 836)}
          onMouseOver={() => setElementOnHover('836')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M127.76,171.7561a1.3019,1.3019,0,0,1,.9033-1.2188l-.0068-.0215a1.1283,1.1283,0,0,1-.7217-1.0224,1.2754,1.2754,0,0,1,1.4082-1.212,1.1754,1.1754,0,0,1,1.3233,1.1211,1.1564,1.1564,0,0,1-.7491,1.0645v.0215a1.2614,1.2614,0,0,1,.918,1.1758,1.4023,1.4023,0,0,1-1.5478,1.3242C128.3338,172.9885,127.76,172.4211,127.76,171.7561Zm2.4512-.0284c0-.5537-.3848-.8193-1.001-.9941a.9881.9881,0,0,0-.8193.9385.8413.8413,0,0,0,.91.8681A.8241.8241,0,0,0,130.2108,171.7277Zm-1.6944-2.2763c0,.455.3428.7.8682.8408a.8972.8972,0,0,0,.6934-.8272.7072.7072,0,0,0-.77-.7421A.7239.7239,0,0,0,128.5164,169.4514Z"
          />
          <path
            className="cls-2"
            d="M131.5536,172.2043a2.0663,2.0663,0,0,0,1.0087.2871c.7911,0,1.0362-.5049,1.0293-.8828-.0068-.6377-.581-.91-1.1767-.91h-.3428v-.4629h.3428c.4482,0,1.0156-.23,1.0156-.77,0-.3642-.2314-.6855-.7978-.6855a1.6779,1.6779,0,0,0-.9112.3008l-.1611-.4483a2.1661,2.1661,0,0,1,1.19-.3506c.8965,0,1.3028.5323,1.3028,1.086a1.1289,1.1289,0,0,1-.84,1.0713v.0146a1.1841,1.1841,0,0,1,1.0147,1.169c0,.7285-.5664,1.3662-1.6592,1.3662a2.3075,2.3075,0,0,1-1.1836-.3086Z"
          />
          <path
            className="cls-2"
            d="M137.5956,168.7932a2.2123,2.2123,0,0,0-.462.0283,1.85,1.85,0,0,0-1.583,1.6172h.0215a1.3879,1.3879,0,0,1,2.4727.9463,1.5225,1.5225,0,0,1-1.5137,1.6035c-.9736,0-1.61-.7569-1.61-1.94a2.8612,2.8612,0,0,1,.7706-2.0517,2.4789,2.4789,0,0,1,1.457-.6729,3.0249,3.0249,0,0,1,.4473-.0341Zm-.1749,2.6259a.9244.9244,0,0,0-.9384-1.0429,1.0118,1.0118,0,0,0-.8819.56.5117.5117,0,0,0-.07.2725c.0137.75.3574,1.3027,1.001,1.3027C137.0633,172.5119,137.4207,172.0705,137.4207,171.4191Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_3krs_as837_B'
              ? 'white-text'
              : elementOnHover === '837'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '837') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(3, 837)}
          onMouseOver={() => setElementOnHover('837')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M66.0091,139.0109a1.3012,1.3012,0,0,1,.9033-1.2187l-.0068-.0215a1.1275,1.1275,0,0,1-.7217-1.0225,1.2752,1.2752,0,0,1,1.4077-1.2119,1.1757,1.1757,0,0,1,1.3238,1.1211,1.1572,1.1572,0,0,1-.749,1.0645v.0215a1.2616,1.2616,0,0,1,.9174,1.1757,1.4024,1.4024,0,0,1-1.5478,1.3243C66.5833,140.2434,66.0091,139.676,66.0091,139.0109Zm2.4512-.0283c0-.5537-.3853-.8193-1.0015-.9941a.9881.9881,0,0,0-.8193.9385.8416.8416,0,0,0,.91.8681A.8242.8242,0,0,0,68.46,138.9826Zm-1.6948-2.2763c0,.455.3432.7.8681.8408a.8977.8977,0,0,0,.6939-.8272.7076.7076,0,0,0-.77-.7422A.7241.7241,0,0,0,66.7655,136.7063Z"
          />
          <path
            className="cls-2"
            d="M69.8031,139.4592a2.0659,2.0659,0,0,0,1.0083.2871c.7915,0,1.0366-.5049,1.03-.8828-.0074-.6377-.5816-.91-1.1768-.91h-.3433V137.49h.3433c.4482,0,1.0156-.23,1.0156-.77,0-.3642-.2314-.6855-.7983-.6855a1.6761,1.6761,0,0,0-.9107.3008l-.1611-.4483a2.1661,2.1661,0,0,1,1.19-.3506c.8965,0,1.3028.5323,1.3028,1.086a1.13,1.13,0,0,1-.84,1.0713v.0146a1.1845,1.1845,0,0,1,1.0152,1.169c0,.7285-.5669,1.3662-1.6592,1.3662a2.3111,2.3111,0,0,1-1.1841-.3086Z"
          />
          <path
            className="cls-2"
            d="M76.2025,135.6135v.4062l-1.9815,4.1465h-.6377l1.9747-4.0273v-.0137H73.3314v-.5117Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_3krs_as835_B'
              ? 'white-text'
              : elementOnHover === '835'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '835') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(3, 835)}
          onMouseOver={() => setElementOnHover('835')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M127.3748,131.7932a1.3018,1.3018,0,0,1,.9034-1.2188l-.0069-.0215a1.1281,1.1281,0,0,1-.7216-1.0224,1.2753,1.2753,0,0,1,1.4082-1.2119,1.1752,1.1752,0,0,1,1.3232,1.1211,1.1564,1.1564,0,0,1-.749,1.0644v.0215a1.2613,1.2613,0,0,1,.9179,1.1758,1.4023,1.4023,0,0,1-1.5478,1.3242C127.9491,133.0256,127.3748,132.4582,127.3748,131.7932Zm2.4512-.0284c0-.5537-.3847-.8193-1.001-.9941a.9881.9881,0,0,0-.8193.9385.8414.8414,0,0,0,.91.8681A.824.824,0,0,0,129.826,131.7648Zm-1.6943-2.2763c0,.4551.3428.7.8681.8408a.8972.8972,0,0,0,.6934-.8271.7073.7073,0,0,0-.77-.7422A.7239.7239,0,0,0,128.1317,129.4885Z"
          />
          <path
            className="cls-2"
            d="M131.1688,132.2414a2.0664,2.0664,0,0,0,1.0088.2871c.791,0,1.0361-.5049,1.0293-.8828-.0069-.6377-.5811-.91-1.1768-.91h-.3428v-.4628h.3428c.4483,0,1.0156-.23,1.0156-.77,0-.3643-.2314-.6856-.7978-.6856a1.6775,1.6775,0,0,0-.9111.3008l-.1612-.4483a2.1663,2.1663,0,0,1,1.19-.35c.8964,0,1.3027.5322,1.3027,1.0859a1.1289,1.1289,0,0,1-.84,1.0713v.0146a1.1842,1.1842,0,0,1,1.0147,1.169c0,.7285-.5664,1.3662-1.6592,1.3662a2.3068,2.3068,0,0,1-1.1836-.3086Z"
          />
          <path
            className="cls-2"
            d="M137.33,128.9143h-1.7363l-.1758,1.17a2.35,2.35,0,0,1,.3721-.0283,1.9268,1.9268,0,0,1,.9805.2451,1.3,1.3,0,0,1,.6513,1.17,1.58,1.58,0,0,1-1.6953,1.5547,2.2355,2.2355,0,0,1-1.1348-.28l.1543-.4687a2.05,2.05,0,0,0,.9737.2519,1.0048,1.0048,0,0,0,1.0781-.9883c-.0068-.5879-.3984-1.0078-1.31-1.0078a4.6281,4.6281,0,0,0-.63.0489l.294-2.1856H137.33Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_3krs_as834_B'
              ? 'white-text'
              : elementOnHover === '834'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '834') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(3, 834)}
          onMouseOver={() => setElementOnHover('834')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M127.3748,96.4733a1.3018,1.3018,0,0,1,.9034-1.2187l-.0069-.0215a1.1282,1.1282,0,0,1-.7216-1.0225,1.2753,1.2753,0,0,1,1.4082-1.2119,1.1752,1.1752,0,0,1,1.3232,1.1211,1.1564,1.1564,0,0,1-.749,1.0645v.0215a1.2613,1.2613,0,0,1,.9179,1.1757,1.4024,1.4024,0,0,1-1.5478,1.3243C127.9491,97.7058,127.3748,97.1384,127.3748,96.4733Zm2.4512-.0283c0-.5537-.3847-.8193-1.001-.9941a.9881.9881,0,0,0-.8193.9385.8414.8414,0,0,0,.91.8681A.824.824,0,0,0,129.826,96.445Zm-1.6943-2.2763c0,.455.3428.7.8681.8408a.8972.8972,0,0,0,.6934-.8272.7073.7073,0,0,0-.77-.7422A.724.724,0,0,0,128.1317,94.1687Z"
          />
          <path
            className="cls-2"
            d="M131.1688,96.9216a2.0664,2.0664,0,0,0,1.0088.2871c.791,0,1.0361-.5049,1.0293-.8828-.0069-.6377-.5811-.91-1.1768-.91h-.3428v-.4629h.3428c.4483,0,1.0156-.23,1.0156-.77,0-.3642-.2314-.6855-.7978-.6855a1.6775,1.6775,0,0,0-.9111.3008l-.1612-.4483a2.1662,2.1662,0,0,1,1.19-.3506c.8964,0,1.3027.5323,1.3027,1.086a1.1289,1.1289,0,0,1-.84,1.0713v.0146a1.184,1.184,0,0,1,1.0147,1.169c0,.7285-.5664,1.3662-1.6592,1.3662a2.3078,2.3078,0,0,1-1.1836-.3086Z"
          />
          <path
            className="cls-2"
            d="M136.5174,97.6286V96.3894h-2.1142v-.4063l2.03-2.9072H137.1v2.83h.6367v.4834H137.1v1.2392Zm0-1.7226V94.3855c0-.2374.0078-.4756.0215-.7139h-.0215c-.14.2656-.2519.4619-.3779.6719l-1.1133,1.5478v.0147Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_3krs_as833_B'
              ? 'white-text'
              : elementOnHover === '833'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '833') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(3, 833)}
          onMouseOver={() => setElementOnHover('833')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M127.3748,67.0588a1.3019,1.3019,0,0,1,.9034-1.2188l-.0069-.0214a1.1282,1.1282,0,0,1-.7216-1.0225,1.2753,1.2753,0,0,1,1.4082-1.2119,1.1752,1.1752,0,0,1,1.3232,1.1211,1.1564,1.1564,0,0,1-.749,1.0644v.0215a1.2614,1.2614,0,0,1,.9179,1.1758,1.4023,1.4023,0,0,1-1.5478,1.3242C127.9491,68.2912,127.3748,67.7238,127.3748,67.0588Zm2.4512-.0283c0-.5537-.3847-.8194-1.001-.9942a.9882.9882,0,0,0-.8193.9385.8414.8414,0,0,0,.91.8682A.8241.8241,0,0,0,129.826,67.0305Zm-1.6943-2.2764c0,.4551.3428.7.8681.8408a.8972.8972,0,0,0,.6934-.8271.7073.7073,0,0,0-.77-.7422A.7239.7239,0,0,0,128.1317,64.7541Z"
          />
          <path
            className="cls-2"
            d="M131.1688,67.507a2.0653,2.0653,0,0,0,1.0088.2871c.791,0,1.0361-.5048,1.0293-.8828-.0069-.6377-.5811-.91-1.1768-.91h-.3428v-.4629h.3428c.4483,0,1.0156-.2305,1.0156-.7705,0-.3643-.2314-.6856-.7978-.6856a1.6784,1.6784,0,0,0-.9111.3008l-.1612-.4482a2.1662,2.1662,0,0,1,1.19-.3506c.8964,0,1.3027.5322,1.3027,1.0859a1.1289,1.1289,0,0,1-.84,1.0713v.0147a1.184,1.184,0,0,1,1.0147,1.1689c0,.7285-.5664,1.3662-1.6592,1.3662a2.3068,2.3068,0,0,1-1.1836-.3086Z"
          />
          <path
            className="cls-2"
            d="M134.76,67.507a2.0723,2.0723,0,0,0,1.0088.2871c.792,0,1.0371-.5048,1.0293-.8828-.0068-.6377-.5811-.91-1.1768-.91h-.3427v-.4629h.3427c.4483,0,1.0157-.2305,1.0157-.7705,0-.3643-.23-.6856-.7979-.6856a1.6787,1.6787,0,0,0-.9111.3008l-.16-.4482a2.1635,2.1635,0,0,1,1.19-.3506c.8964,0,1.3027.5322,1.3027,1.0859a1.13,1.13,0,0,1-.8408,1.0713v.0147a1.1849,1.1849,0,0,1,1.0156,1.1689c0,.7285-.5674,1.3662-1.66,1.3662a2.3128,2.3128,0,0,1-1.1836-.3086Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_3krs_as832_B'
              ? 'white-text'
              : elementOnHover === '832'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '832') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(3, 832)}
          onMouseOver={() => setElementOnHover('832')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M127.76,33.4338a1.3006,1.3006,0,0,1,.9033-1.2188l-.0068-.0214a1.1267,1.1267,0,0,1-.7217-1.0225,1.275,1.275,0,0,1,1.4072-1.2119,1.1762,1.1762,0,0,1,1.3243,1.1211,1.1579,1.1579,0,0,1-.7491,1.0644v.0215a1.2618,1.2618,0,0,1,.917,1.1758,1.4023,1.4023,0,0,1-1.5478,1.3242C128.3338,34.6662,127.76,34.0988,127.76,33.4338Zm2.4512-.0283c0-.5537-.3858-.8194-1.002-.9942a.9882.9882,0,0,0-.8193.9385.842.842,0,0,0,.91.8682A.8244.8244,0,0,0,130.2108,33.4055Zm-1.6953-2.2764c0,.4551.3437.7.8681.8408a.8982.8982,0,0,0,.6944-.8271.7078.7078,0,0,0-.77-.7422A.7242.7242,0,0,0,128.5155,31.1291Z"
          />
          <path
            className="cls-2"
            d="M131.5536,33.882a2.0644,2.0644,0,0,0,1.0078.2871c.792,0,1.0371-.5048,1.03-.8828-.0078-.6377-.582-.91-1.1767-.91h-.3438v-.4629h.3438c.4482,0,1.0156-.23,1.0156-.77,0-.3643-.2314-.6856-.7988-.6856a1.6751,1.6751,0,0,0-.91.3008L131.56,30.31a2.1661,2.1661,0,0,1,1.19-.3506c.8965,0,1.3028.5322,1.3028,1.0859a1.13,1.13,0,0,1-.8409,1.0713v.0147A1.1849,1.1849,0,0,1,134.2284,33.3c0,.7285-.5674,1.3662-1.6592,1.3662a2.3137,2.3137,0,0,1-1.1846-.3086Z"
          />
          <path
            className="cls-2"
            d="M134.9979,34.5891v-.378l.4834-.47c1.1621-1.1064,1.6875-1.6943,1.6943-2.3809a.8117.8117,0,0,0-.9033-.89,1.5527,1.5527,0,0,0-.9668.3848l-.1963-.4336a1.997,1.997,0,0,1,1.2891-.4629,1.2811,1.2811,0,0,1,1.3935,1.3242c0,.8408-.6093,1.52-1.5683,2.4443l-.3643.336v.0136h2.0449v.5118Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_3krs_as831_B'
              ? 'white-text'
              : elementOnHover === '831'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '831') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(3, 831)}
          onMouseOver={() => setElementOnHover('831')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M67.15,65.8088a1.3013,1.3013,0,0,1,.9034-1.2188l-.0069-.0214a1.1274,1.1274,0,0,1-.7216-1.0225,1.2751,1.2751,0,0,1,1.4077-1.2119,1.1757,1.1757,0,0,1,1.3237,1.1211,1.1572,1.1572,0,0,1-.749,1.0644v.0215a1.2616,1.2616,0,0,1,.9175,1.1758,1.4024,1.4024,0,0,1-1.5479,1.3242C67.724,67.0412,67.15,66.4738,67.15,65.8088Zm2.4512-.0283c0-.5537-.3852-.8194-1.0014-.9942a.9882.9882,0,0,0-.8194.9385.8417.8417,0,0,0,.91.8682A.8243.8243,0,0,0,69.6009,65.7805Zm-1.6948-2.2764c0,.4551.3433.7.8682.8408a.8977.8977,0,0,0,.6938-.8271.7075.7075,0,0,0-.7705-.7422A.7241.7241,0,0,0,67.9061,63.5041Z"
          />
          <path
            className="cls-2"
            d="M70.9437,66.257a2.0648,2.0648,0,0,0,1.0083.2871c.7915,0,1.0366-.5048,1.03-.8828-.0073-.6377-.5816-.91-1.1768-.91h-.3432v-.4629h.3432c.4483,0,1.0156-.2305,1.0156-.7705,0-.3643-.2314-.6856-.7983-.6856a1.6769,1.6769,0,0,0-.9106.3008l-.1612-.4482a2.1662,2.1662,0,0,1,1.1905-.3506c.8964,0,1.3027.5322,1.3027,1.0859a1.1294,1.1294,0,0,1-.84,1.0713v.0147a1.1845,1.1845,0,0,1,1.0151,1.1689c0,.7285-.5669,1.3662-1.6592,1.3662a2.31,2.31,0,0,1-1.1841-.3086Z"
          />
          <path
            className="cls-2"
            d="M75.7259,62.9855h-.0146l-.791.4278-.1192-.47.9942-.5323h.5253v4.5528h-.5947Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  831: [47.2444, 70.5933],
  832: [165.102, 35.5498],
  833: [165.102, 64.5176],
  834: [165.102, 94.2659],
  835: [165.102, 137.3823],
  836: [165.102, 168.3314],
  837: [47.2444, 130.5971],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #86cfc2;
  }

  .cls-2,
  .cls-9 {
    fill: #004a33;
  }

  .cls-3 {
    fill: #537f6e;
  }

  .cls-4,
  .cls-5,
  .cls-6 {
    fill: none;
  }

  .cls-4,
  .cls-5,
  .cls-6,
  .cls-9 {
    stroke: #004a33;
    stroke-miterlimit: 10;
  }

  .cls-4 {
    stroke-width: 1.5px;
  }

  .cls-5 {
    stroke-width: 0.85px;
  }

  .cls-6 {
    stroke-width: 0.3px;
  }

  .cls-7 {
    fill: #a5dacf;
  }

  .cls-8 {
    fill: #65c5b4;
  }

  .cls-9 {
    stroke-width: 0.75px;
  }

  .cls-10 {
    fill: url(#linear-gradient);
  }

  .cls-11 {
    fill: url(#linear-gradient-2);
  }

  .cls-12 {
    fill: url(#linear-gradient-3);
  }

  .cls-13 {
    fill: url(#linear-gradient-4);
  }

  .cls-14 {
    fill: url(#linear-gradient-5);
  }

  .cls-15 {
    fill: url(#linear-gradient-6);
  }

  .cls-16 {
    fill: url(#linear-gradient-7);
  }

  #up:hover,
  #down:hover {
    cursor: pointer;

    .cls-8 {
      fill: var(--secondary-color);
    }

    .cls-2 {
      fill: var(--green-color);
    }
  }

  .disable-lift-btn {
    cursor: not-allowed;

    .cls-1 {
      fill: var(--grey);
    }

    .cls-6 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--primary-color);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text .cls-2 {
    fill: var(--light);
    cursor: pointer;
  }

  .green-text .cls-2 {
    fill: var(--green-color);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white-sold .cls-6 {
    fill: #fff;
    cursor: pointer;
  }
`
