export const FLOOR_1_DATA = [
  {
    floor: 1,
    building: 'B',
    apartmentNumber: 811,
    sceneName: 'scene_Lounatuuli_1krs_as811_B',
    sceneName_future: 'scene_Lounatuuli_1krs_as811_B_future',
    name: '811',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 823,
      3: 835,
      4: 845,
      5: 855,
      6: 865,
      7: 875,
    },
  },
  {
    floor: 1,
    building: 'B',
    apartmentNumber: 812,
    sceneName: 'scene_Lounatuuli_1krs_as812_B',
    sceneName_future: 'scene_Lounatuuli_1krs_as812_B_future',
    name: '812',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 824,
      3: 836,
      4: 846,
      5: 856,
      6: 866,
      7: 876,
    },
  },
]
