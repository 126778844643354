export const FLOOR_4_DATA = [
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 841,
    sceneName: 'scene_Lounatuuli_4krs_as841_B',
    sceneName_future: 'scene_Lounatuuli_4krs_as841_B_future',
    name: '841',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 821,
      3: 831,
      5: 851,
      6: 861,
      7: 871,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 842,
    sceneName: 'scene_Lounatuuli_4krs_as842_B',
    sceneName_future: 'scene_Lounatuuli_4krs_as842_B_future',
    name: '842',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      3: 832,
      5: 852,
      6: 862,
      7: 872,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 843,
    sceneName: 'scene_Lounatuuli_4krs_as843_B',
    sceneName_future: 'scene_Lounatuuli_4krs_as843_B_future',
    name: '843',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      3: 833,
      5: 853,
      6: 863,
      7: 873,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 844,
    sceneName: 'scene_Lounatuuli_4krs_as844_B',
    sceneName_future: 'scene_Lounatuuli_4krs_as844_B_future',
    name: '844',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 822,
      3: 834,
      5: 854,
      6: 864,
      7: 874,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 845,
    sceneName: 'scene_Lounatuuli_4krs_as845_B',
    sceneName_future: 'scene_Lounatuuli_4krs_as845_B_future',
    name: '845',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 823,
      3: 835,
      5: 855,
      6: 865,
      7: 875,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 846,
    sceneName: 'scene_Lounatuuli_4krs_as846_B',
    sceneName_future: 'scene_Lounatuuli_4krs_as846_B_future',
    name: '846',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 824,
      3: 836,
      5: 856,
      6: 866,
      7: 876,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 847,
    sceneName: 'scene_Lounatuuli_4krs_as847_B',
    sceneName_future: 'scene_Lounatuuli_4krs_as847_B_future',
    name: '847',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 824,
      3: 837,
      5: 857,
      6: 867,
      7: 877,
    },
  },
]
