export const FLOOR_6_DATA = [
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 861,
    sceneName: 'scene_Lounatuuli_6krs_as861_B',
    sceneName_future: 'scene_Lounatuuli_6krs_as861_B_future',
    name: '861',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 821,
      3: 831,
      4: 841,
      5: 851,
      7: 871,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 862,
    sceneName: 'scene_Lounatuuli_6krs_as862_B',
    sceneName_future: 'scene_Lounatuuli_6krs_as862_B_future',
    name: '862',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      3: 832,
      4: 842,
      5: 852,
      7: 872,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 863,
    sceneName: 'scene_Lounatuuli_6krs_as863_B',
    sceneName_future: 'scene_Lounatuuli_6krs_as863_B_future',
    name: '863',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 821,
      3: 833,
      4: 843,
      5: 853,
      7: 873,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 864,
    sceneName: 'scene_Lounatuuli_6krs_as864_B',
    sceneName_future: 'scene_Lounatuuli_6krs_as864_B_future',
    name: '864',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 822,
      3: 834,
      4: 844,
      5: 854,
      7: 874,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 865,
    sceneName: 'scene_Lounatuuli_6krs_as865_B',
    sceneName_future: 'scene_Lounatuuli_6krs_as865_B_future',
    name: '865',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 823,
      3: 835,
      4: 845,
      5: 855,
      7: 875,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 866,
    sceneName: 'scene_Lounatuuli_6krs_as866_B',
    sceneName_future: 'scene_Lounatuuli_6krs_as866_B_future',
    name: '866',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 824,
      3: 836,
      4: 846,
      5: 856,
      7: 876,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 867,
    sceneName: 'scene_Lounatuuli_6krs_as867_B',
    sceneName_future: 'scene_Lounatuuli_6krs_as867_B_future',
    name: '867',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      2: 824,
      3: 837,
      4: 847,
      5: 857,
      7: 877,
    },
  },
]
